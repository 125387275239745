import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NavbarActions from '../../common/navbar/NavbarAction';
import * as PBActions from '../../common/progressbar/ProgressBarAction';
import * as CallActions from '../../calls/CallsAction';


import PG_Layout from '../PG_Layout';
import { navigateTo, newPromise, isLength } from '../../helpers/helpers';
import config from '../../../config/configApp';
import { getMenus } from '../../common/navbar/MenuData';

class PG_Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            admin : { list :[]}
        }
    }
    getMenu(){
        if(this.props.navbar && this.props.navbar.user && this.props.navbar.user.control){
            let {admin} = this.state;
            admin.list = getMenus({ usertype : String(this.props.navbar.user.control).toLowerCase() });
            this.setState({ admin });
        }
        return newPromise({})
    }
    overview(){
        let adminlist = this.state.admin.list;
        if(isLength(adminlist)){
            for(var i in adminlist){
                let iobj = adminlist[i];
                if(iobj.table){
                    let exec_input = { action: "overview", reducerName: iobj.table+"_overview", querys : [ "SELECT COUNT(*) AS total_item FROM ",iobj.table," WHERE deleted_at IS NULL" ].join(" ") };
                    newPromise(exec_input,1000*i).then((res)=>{
                        this.props.CallActions.exec(res, "products/");
                    })
                }
            }
        }else{
            newPromise({},3000).then(()=>{
                this.overview();
            })
        }
    }
    componentDidMount() {
        this.props.PBActions.enable({ show: false });
        this.props.NavbarActions.update({ type: "admin", "current_page": "/dashboard" }).then(()=>{
            this.getMenu();
            this.overview();
        });
    }
    widgetHeader(p){
        return (
            <div className="widget-header">
                <div className="flex pad fxc flex-icon align-items-center pad grow">
                    <div className="flex-10">
                        <h6 className="lead h7 marg0 text-ellipsis flex-11">{p.title}</h6>
                    </div>
                    <div className="icon-w padreset">
                        <a className="anchor light fill circle oh w block text-center" href={null} onClick={()=>navigateTo({url:p.url+"/add"})}>
                            <span className="padx2"><i className="ion-plus"></i></span>
                        </a>
                    </div>
                    <div className="padx2 padreset"></div>
                    <div className="icon-w padreset">
                        <a className="anchor light fill circle oh w block text-center" href={null} onClick={()=>navigateTo({url:p.url})}>
                            <span className="padx2"><i className="ion-arrow-right-c"></i></span>
                        </a>
                    </div>
                    <div className="padr10 padreset"></div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <React.Fragment>
                <PG_Layout>
                    <div className="desktop">
                        <div className="flex wrap pad">
                            <div className="flex-12">
                                <h1 className="lead h5 pady10 marg0">Hello! Welcome to {config.appinfo.appname}</h1>
                                <div className="divider nospace"></div>
                            </div>
                            <div className="flex-12">
                                <div className="flex wrap align-items-stretch grow">
                                    {
                                        (this.state.admin.list.length) && (
                                            this.state.admin.list.map((item, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <div className="">
                                                            <a className="block button btn-default medium b1x" onClick={()=>{navigateTo({url:item.url})}} href={null} title={item.label}>
                                                                <span className="menu-txt">{item.label}</span>
                                                            </a>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        )||""
                                    }
                                </div>
                            </div>
                            <div className="flex-12">
                                <div className="flex wrap grow pad flex-container align-items-stretch">
                                    {
                                        (isLength(this.state.admin.list)) && (
                                            this.state.admin.list.map((item,i)=>{
                                                let overview = this.props.calls[item.table+"_overview"];
                                                return (
                                                    <React.Fragment key={i}>
                                                        {
                                                            (item.table && overview && isLength(overview.list)) && (
                                                                <React.Fragment>
                                                                <div className="flex-4 sm-6 xsm-12">
                                                                    <div className="widget oddeven h">
                                                                        {this.widgetHeader({title: [item.label,"Overview"].join(" "),"url":item.url})}
                                                                        <div className="pad10">
                                                                            <p className="flex wrap align-items-center justify-around padcell bg grey2 pad5">
                                                                                <span className="lead h1 font-light">{overview.list[0].total_item||"0"}</span>
                                                                                <span className="font-semibold">{item.label} Items</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </PG_Layout>
            </React.Fragment>
        )
    }
}

PG_Dashboard.propTypes = {
    NavbarActions: PropTypes.object.isRequired,
    PBActions: PropTypes.object.isRequired,
    CallActions: PropTypes.object.isRequired,
    calls : PropTypes.object,
    navbar : PropTypes.object
};

function mapStateToProps(state) {
    return {
        calls : state.CallsReducer, navbar : state.NavbarR
    };
}

function mapDispatchToProps(dispatch) {
    return {
        NavbarActions: bindActionCreators(NavbarActions, dispatch),
        PBActions: bindActionCreators(PBActions, dispatch),
        CallActions: bindActionCreators(CallActions, dispatch),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_Dashboard);