import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as PBActions from '../progressbar/ProgressBarAction';
import * as Actions from './TopbarAction';
import config from '../../../config/configApp';
import { navigateTo, newPromise, setLS, encrypt } from '../../helpers/helpers';

class Topbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toolDropdown: {}
        }
    }
    onTogglePress() {
        this.props.Actions.update({ sidenav: !this.props.topbar.sidenav });
    }
    toolDropdownToggleMove(p) {
        let { toolDropdown } = this.state;
        toolDropdown[p.id] = false; this.setState({ toolDropdown });
    }
    toolDropdownToggle(p) {
        let { toolDropdown } = this.state;
        toolDropdown[p.id] = !toolDropdown[p.id]; this.setState({ toolDropdown });
    }
    logout() {
        this.props.PBActions.enable({ show: true, text: "Logging out!" });
        setLS({ key: "logger", value: encrypt({}, true) }).then(() => {
            this.props.Actions.update(config.data.logReset).then(() => {
                newPromise({}, 1000).then(() => {
                    this.props.PBActions.enable({ show: false }); navigateTo({ url: '/login' });
                });
            });
        });
    }
    render() {
        return (
            <React.Fragment>
                <div className="topbar flex fxc grow">
                    <div className="toggle-menu-col">
                        <a href={null} className={["toggle-menu", String(this.props.topbar.sidenav)].join(" ")} onClick={this.onTogglePress.bind(this)}>
                            <span>
                                <i className="ion-android-menu"></i>
                            </span>
                            <span>
                                <i className="ion-arrow-left-c"></i>
                            </span>
                        </a>
                    </div>
                    <div className="brand-col">
                        <h1 className="brand-logo">
                            <a href={null} className="flex fxc">
                                <span>
                                    {config.appinfo.appname}
                                </span>
                                <span><span className="badge">{config.appinfo.version}</span></span>
                            </a>
                        </h1>
                    </div>
                    <div>
                        <div className="toollist align-end">
                            <ul>
                                <li className={["tool-dropdown", (this.state.toolDropdown.profile) ? "open" : ''].join(" ")} onMouseOut={this.toolDropdownToggleMove.bind(this, { id: "profile" })}>
                                    <a href={null} onClick={this.toolDropdownToggle.bind(this, { id: "profile" })} >
                                        <span><i className="ion-power"></i></span>
                                    </a>
                                    <div className="dropdown-target">
                                        <ul>
                                            <li>
                                                <a className="" href={null} onClick={() => navigateTo({ url: '/user/' + this.props.navbar.user.id })}>
                                                    <span className="flex fxc flex-icon">
                                                        <span className="icon-w"><i className="ion-ios-contact"></i></span>
                                                        <span>Profile</span>
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="" href={null} onClick={this.logout.bind(this)}>
                                                    <span className="flex fxc flex-icon">
                                                        <span className="icon-w"><i className=""></i></span>
                                                        <span>Sign out</span>
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Topbar.propTypes = {
    topbar: PropTypes.object,
    navbar: PropTypes.object,
    Actions: PropTypes.object,
    PBActions: PropTypes.object
};

function mapStateToProps(state) {
    return {
        topbar: state.TopbarR,
        navbar: state.NavbarR
    };
}

function mapDispatchToProps(dispatch) {
    return {
        Actions: bindActionCreators(Actions, dispatch),
        PBActions: bindActionCreators(PBActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Topbar);