import {callsActionType} from './CallsActionType';
import {callsService} from './CallsService';
import { newPromise } from '../helpers/helpers';

export function exec(payload,endpoint) {
    return dispatch => {
        dispatch(request(payload));
        let call = callsService.connect(payload,endpoint);
            call.then(
                res => {  dispatch(success(res)); },
                error => { dispatch(failure(error.toString())); }
            );
        return call;
    };
    function request(res) { return { type: callsActionType.request, ...res } }
    function success(res) { return { type: callsActionType.success, ...res } }
    function failure(error) { return { type: callsActionType.fail, ...error } }
}
export function fileupload(payload,endpoint) {
    return dispatch => {
        dispatch(request(payload));
        let call = callsService.fileupload(payload,endpoint);
            call.then(
                res => {  dispatch(success(res)); },
                error => { dispatch(failure(error.toString())); }
            );
        return call;
    };
    function request(res) { return { type: callsActionType.request, ...res } }
    function success(res) { return { type: callsActionType.success, ...res } }
    function failure(error) { return { type: callsActionType.fail, ...error } }
}
export function updateAny(payload) {
    return dispatch => { dispatch(update(payload)); 
        return newPromise({});
    };
    function update(res) { return { ...res, type: callsActionType.updateAny } }
}
export function patch(payload) {
    return dispatch => { dispatch(patch(payload)); 
        return newPromise({});
    };
    function patch(res) { return { patch:res, type: callsActionType.patch } }
}