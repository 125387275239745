import React, { useState, useRef } from 'react';
import { TagInput } from '../edittext/TagInput';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import DateTimePicker from 'react-datetime-picker';

export function FormField(props) {
    const [value, setValue] = useState(props.defaultValue);
    const inputRef = useRef(null);

    const onCheckboxChange = (e) => {
        let { value, checked } = e.target;
        if (typeof props.onChange === "function") {
            props.onChange({ value, checked,inputRef }, props);
        }
    }
    const onRadioChange = (e) => {
        let { value, checked } = e.target;
        if (typeof props.onChange === "function") {
            props.onChange({ value, checked,inputRef }, props);
        }
    }
    const onTagSave = (res) => {
        if (typeof props.onChange === "function") {
            props.onChange({ value: res.tags,inputRef }, props);
        }
    }
    const onTagCallback = (res) => {
        if (typeof props.callback === "function") {
            props.callback(res, props);
        }
    }
    const onDateChange = (value,props) => {
        setValue(inputRef.current.value);
        if (typeof props.onChange === "function") {
            props.onChange({ value, inputRef }, props);
        }
    }
    const onChange = () => {
        updateFormat("onChangeFormat");
        setValue(inputRef.current.value);
        if (typeof props.onChange === "function") {
            props.onChange({ value, inputRef }, props);
        }
    }
    const onFileChange = (e) => {
        setValue(inputRef.current.value);
        if (typeof props.onChange === "function") {
            props.onChange({ value, inputRef, e }, props);
        }
    }
    const onBlur = () => {
        updateFormat("onBlurFormat");
        setValue(inputRef.current.value);
        if (props.onBlur) {
            props.onBlur({ value, inputRef }, props);
        }
    }
    const formatField= {
        urlFormat : (value)=>{
            let _str = String("-"+value).replace(/\s/g,"-");
            _str = _str.replace(/.([a-z-/#]{0,})/ig,"$1")
            _str = _str.replace(/[-\s]{2,}|[-\s]{2,}$/,"-");
            _str = _str.replace(/^[-\s]/,"-");
            return _str;
        },
        hypen_word_num : (value)=>{
            let _str = String("_"+value).replace(/[\s_]/g,"-");
            _str = _str.replace(/.([a-z0-9-/]{0,})/ig,"$1")
            _str = _str.replace(/[-\s]{2,}|[-\s]{2,}$/,"-");
            _str = _str.replace(/^[-\s]/,"-");
            return _str;
        },
        underscore_word : (value)=>{
            let _str = String("_"+value).replace(/[\s-]/g,"_");
            _str = _str.replace(/.([a-z_/]{0,})/ig,"$1")
            _str = _str.replace(/[_\s]{2,}|[_\s]{2,}$/,"_");
            _str = _str.replace(/^[_\s]/,"_");
            return _str;
        },
        underscore_word_num : (value)=>{
            let _str = String("_"+value).replace(/[\s-]/g,"_");
            _str = _str.replace(/.([a-z0-9_/]{0,})/ig,"$1")
            _str = _str.replace(/[_\s]{2,}|[_\s]{2,}$/,"_");
            _str = _str.replace(/^[_\s]/,"_");
            return _str;
        },
        underscore_word_comma : (value)=>{
            let _str = String("_"+value).replace(/[\s-]/g,"_");
            _str = _str.replace(/.([a-z0-9_,]{0,})/ig,"$1")
            _str = _str.replace(/[\s]{2,}|[\s]{2,}$/,"_");
            _str = _str.replace(/^[_\s]/,"_");
            return _str;
        },
        lowerCase : (value)=>{
            return String(value).toLowerCase();
        },
        lastCharAlphaOnly : (value)=>{
            let _str= String(value).replace(/(.{0,})[-]{1,}$/g,"$1");
            return _str;
        },
        firstCharAlphaOnly : (value)=>{
            return String(value).replace(/^[-]{0,}(.{0,})/,"$1");
        },
        amountOnly : (value)=>{
            let _str = String("_"+value).replace(/\s{0,}/g,"");
            _str = _str.replace(/.([0-9.]{0,})/ig,"$1")
            return _str;
        }
    }
    const updateFormatValue=(value,event)=>{
        return formattingValue(value,event);
    }
    const formattingValue=(value,type)=>{
        let _value = value;
        if(props[type]){
            for(var i in props[type]){
                if(typeof formatField[props[type][i]] === "function"){
                    _value = formatField[props[type][i]](_value);
                }
            }
        }
        return _value;
    }
    const updateFormat = (type)=>{
        inputRef.current.value = formattingValue(inputRef.current.value,type);
    }
    const onKeyUp = (e) => {
        let {keyCode} = e;
        updateFormat("onKeyupFormat");
        setValue(inputRef.current.value);
        if (props.onKeyUp) {
            props.onKeyUp({ e:{keyCode}, value:inputRef.current.value, inputRef}, props);
        }
    }
    const isChecked = (item, p) => {
        let opt = { checked: false }, regex = new RegExp(item.value, "i");
        let targetString = (typeof p.defaultValue === "string") ? p.defaultValue : JSON.stringify(p.defaultValue);
        if (p.defaultValue && String(targetString).match(regex)) {
            opt.checked = true;
        }
        return opt.checked;
    }
    const isRadioChecked = (item, p) => {
        let opt = { checked: false }, regex = new RegExp(item.value, "i");
        let targetString = p.defaultValue;
        if (String(targetString).match(regex)) {
            opt.checked = true;
        }
        return opt.checked;
    }
    const onClearValue = ()=>{
        inputRef.current.value = "";
        setValue("");
    }

    return (
        <React.Fragment>
            <div className={["form-group form-group-hover ",(props.formgroupClass)?props.formgroupClass:""].join("")}>
                {
                    (props.label) && (
                        <label className={[(props.isRequired ? 'required' : '')].join(" ")}>{props.label}</label>
                    )
                }
                {(String(props.input.type).match(/^(value)$/)) && (
                    <div {...props.input}>{value}</div>
                )}
                {(String(props.input.type).match(/^(text|password|tel|email)$/)) && (
                    <div className={["relative"].join(" ")}>
                        <input {...props.input} onChange={onChange} onBlur={onBlur} onKeyUp={onKeyUp} ref={inputRef} defaultValue={value} />
                        {
                            (props.removeValue)&&(
                                <span className="abs topright h flex align-items-center">
                                    <a href={null} className="anchor fill grey radius noborder size-xs nolh before-dark" onClick={onClearValue}>
                                        <span className="relative"><i className="ion-close-circled"></i></span>
                                    </a>
                                </span>
                            )
                        }
                    </div>
                )}
                {(String(props.input.type).match(/^(file)$/)) && (
                    <div className={["relative block"].join(" ")}>
                        <input {...props.input} onChange={onFileChange} ref={inputRef} defaultValue={value} />
                    </div>
                )}
                {(props.input.type == "textarea") && (
                    <textarea {...props.input} onChange={onChange} onBlur={onBlur} onKeyUp={onKeyUp} ref={inputRef} defaultValue={value} ></textarea>
                )}
                {(props.input.type == "arraytext") && (
                    <TagInput updateFormatValue={updateFormatValue} onSave={onTagSave} callback={onTagCallback} tags={value || []} limit={props.limit} updown_arrow={props.tagUpDown||false} />
                )}
                {(props.input.type == "datetext") && (
                    <Datetime value={value} onChange={(value)=>{onDateChange(value,props)}} {...props.datetime||{}} inputProps={props.input||{}} />
                )}
                {(props.input.type == "datetimetext") && (
                    <DateTimePicker onChange={(value)=>{onDateChange(value,props)}} ref={inputRef} value={value} {...props.datetime} />                    
                )}
                {(props.input.type == "radio" && props.option.length) && (
                    <div className="flex pad ypad-off">
                        {
                            props.option.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <label className="flex pad ypad-off flex-icon">
                                            <span><input type="radio" id={props.input.name + "[" + i + "]"} name={props.input.name} defaultValue={item.value} checked={isRadioChecked(item, props)} onChange={onRadioChange} /></span>
                                            <span>{item.label}</span>
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
                {(props.input.type == "checkbox" && props.option.length) && (
                    <div className="flex wrap pad ypad-off">
                        {
                            props.option.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <label className="flex pad ypad-off flex-icon">
                                            <span><input type="checkbox" id={props.input.name + "[" + i + "]"} name={props.input.name + "[" + i + "]"} defaultValue={item.value} checked={isChecked(item, props)} onChange={onCheckboxChange} /></span>
                                            <span>{item.label}</span>
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                )}

                {props.children || ""}
            </div>
        </React.Fragment>
    )
}