import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../calls/CallsAction';

import * as PBActions from '../common/progressbar/ProgressBarAction';
import * as NavbarActions from '../common/navbar/NavbarAction';

import { hideProgressBar, setLS, isApiErr } from '../helpers/helpers';
import PG_PublicLayout from '../pages/PG_PublicLayout';
import config from '../../config/configApp';
import ForgotPasswordForm from './ForgotPasswordForm';

class ForgotPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      navbar: {
        "type": "app"
      },
      message: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  handleSubmit(values) {
    const { email } = values;
    this.props.PBActions.enable({ show: true, text: "Authenticating.." });
    let calls = this.props.Actions.exec({
      action: "forgotpassword", tablename: "user", reducerName: "forgotpassword", email,
    }, "authenticate/");
    calls.then((res) => {
      if (isApiErr(res)) {
        this.props.PBActions.enable({ text: res.message || "Sorry, Unable to process. Please try again later." });
      } else {
        let message = res.message || "Sorry, Unable to process. Please try again later.";
        this.props.PBActions.enable({ text: message });
        this.setState({message});
      }
      hideProgressBar(this.props, { duration: 15000 });
    }, () => {
      this.props.PBActions.enable({ text: "Sorry, Unable to process. Please try again later." });
      hideProgressBar(this.props);
    });
    return calls;
  }

  componentDidMount() {
    this.props.NavbarActions.update(config.data.logReset);
    setLS({ key: "logger", value: {} });
  }

  render() {
    return (
      <PG_PublicLayout>
        <div className="flex fxc align-items-center justify-center">
          <div className="flex-4 sm-6 xsm-8 xs-12">
            <div className="widget pad30">
              <h5 className="font-semibold text-center uppercase lead h6 margb30">Forgot Password</h5>
              {
                (this.state.message) && (
                  <React.Fragment>
                    <div dangerouslySetInnerHTML={{__html:this.state.message||""}}></div>
                  </React.Fragment>
                )||(
                    <ForgotPasswordForm onSubmit={this.handleSubmit} onEnter={true} />
                )
              }
            </div>
          </div>
        </div>
      </PG_PublicLayout>
    )
  }
}

ForgotPassword.propTypes = {
  Actions: PropTypes.object.isRequired,
  NavbarActions: PropTypes.object.isRequired,
  navbar: PropTypes.object,
  PBActions: PropTypes.object.isRequired,
  pb: PropTypes.object
};

function mapStateToProps(state) {
  return {
    navbar: state.NavbarR
  };
}

function mapDispatchToProps(dispatch) {
  return {
    Actions: bindActionCreators(Actions, dispatch),
    PBActions: bindActionCreators(PBActions, dispatch),
    NavbarActions: bindActionCreators(NavbarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);