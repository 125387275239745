import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import { deepclone, isAny, isNull, newPromise, strTojson } from '../../helpers/helpers';
import { FormField } from '../formfield/FormField';
import { sectionDataModel } from './section_data_model';


SectionDataFnComp.propTypes = {
    defaultValue: PropTypes.any,
    callback: PropTypes.any,
    stateSearch: PropTypes.any,
    canDeletePermission: PropTypes.any,
    canEditPermission: PropTypes.any,
};

export function SectionDataFnComp({defaultValue, callback, stateSearch, canEditPermission, canDeletePermission}) {
    
    const locSectionModel = sectionDataModel();
    const [value, setValue] = useState(deepclone(defaultValue));
    const [item, setItem] = useState(deepclone(locSectionModel.newitem));
    const [edit, setEdit] = useState(null);
    const [code, setCode] = useState(null);
    const [searchkey, setSearchKey] = useState("");
    const [newKeyName, setNewKeyName] = useState("");
    const [settingId, setSettingId] = useState({i:"all"});
    const carried = {};
    useEffect(() => {
        return () => {}
    }, [item, setItem]);
    
    const _props = function(){
        return {defaultValue,callback,stateSearch,canEditPermission,canDeletePermission};
    }
    const newItem = deepclone(locSectionModel.newitem);
    const keyvalue_format = (key)=>{
        let _key = String("_"+key).replace(/.([a-z_]{0,})/,"$1").toLowerCase();
        _key = String(_key).replace(/^[0-9]{0,}/,"");
        return _key;
    }
    const isEmpty=(val)=>{return String(val).match(/^(\s{0,})$|null|undefined/);}
    const onValueChange = (state,fprop)=>{
        let oldItem = deepclone(item);
        oldItem["value"][fprop.refIndex][fprop.input.name] = state.value||[];
        setItem(oldItem);
    }
    const onValueItemDelete = (p)=>{
        let oldItem = deepclone({...item}), newset = {};
        for(var i in p.item){
            if(i!=p.key){
                newset[i] = p.item[i];
            }
        }
        oldItem.value[p.i] = newset,
        setItem(null);
        newPromise({},10).then(()=>{
            setItem(oldItem);
        });
    }
    const onKeyChange = (state,fprop)=>{
        let oldItem = deepclone(item);
        oldItem[fprop.columnname] = state.inputRef.current.value;
        setItem(oldItem);
    }
    const onNewKeyChange=(state,fprop)=>{
        if(isEmpty(state.inputRef.current.value)){
            setNewKeyName("");
        }else{
            setNewKeyName(fprop.columnname);
        }
        carried["onNewKeyChange"] = {state,fprop}
    }
    const onNewKeyType=(p)=>{
        if(carried["onNewKeyChange"] && carried["onNewKeyChange"].state){
            let {state,fprop} = carried["onNewKeyChange"]
            if(!isEmpty(state.inputRef.current.value)){
                let oldItem = deepclone({...item});
                let keyvalue = keyvalue_format(state.inputRef.current.value);
                if(p.type == "text"){
                    oldItem["value"][fprop.refIndex][keyvalue] = "";
                }
                if(p.type == "array"){
                    oldItem["value"][fprop.refIndex][keyvalue] = [];
                }
                if(state.inputRef,state.inputRef.current){
                    state.inputRef.current.value = "";
                }
                setItem(oldItem);
                setNewKeyName("none");
                carried["onNewKeyChange"]= null;
            }
        }
    }
    const resetData = (p)=>{
        setItem(null);
        newPromise({},10).then(()=>{
            setItem(deepclone({...p.item}));
        });
    }
    const updateValue = (p)=>{
        if(typeof callback === "function"){
            callback(p.value,_props());
        }
    }
    const onAddKeyPair = ()=>{
        if(!isEmpty(item.key)){
            let oldvalue = deepclone(value);
            oldvalue.list = oldvalue.list||[];
            let isEdit = (edit==null)?false:true;
            if(isEdit){
                oldvalue.list[edit] = {...item};
            }else{
                oldvalue.list.push({...item});
            }
            setValue(oldvalue);
            updateValue({value:oldvalue});
            resetData({item:{...newItem}});
            setEdit(null);
        }
    }
    const onEditData = (p)=>{
        setCode(null);
        setEdit(p.i); resetData(p);
        setSearchKey(null); setSearchKey(""); 
    }
    const onJsonCode = (p)=>{
        setCode(p.i); setEdit(p.i); resetData(p);
        setSearchKey(null); setSearchKey(""); 
    }
    const orderMoveUp = (p)=>{
        let oldvalue = deepclone(value);
        let newSet =  {...oldvalue.list[p.i]};
        oldvalue.list.splice(p.i,1);
        oldvalue.list.splice(p.i-1,0,newSet);
        updateValue({value:oldvalue}); setValue(oldvalue);
    }
    const onDeleteData = (p)=>{
        let oldvalue = deepclone(value);
        oldvalue.list.splice(p.i,1);
        updateValue({value:oldvalue});
        setValue(oldvalue);
    }
    const onCloneData = (p)=>{
        let oldvalue = deepclone(value);
        let newSet =  {...oldvalue.list[p.i]};
        oldvalue.list.splice(p.i,0,newSet);
        updateValue({value:oldvalue}); setValue(oldvalue);
    }
    const onAddValueSet = (p)=>{
        let oldItem = deepclone(item), valueset = {...p.item};
        for(var i in valueset){
            valueset[i] = "";
        }
        oldItem.value.push(valueset); setItem(oldItem);
    }
    const onDeleteValueSet = (p)=>{
        let oldItem = deepclone(item);
        oldItem.value.splice(p.i,1);
        setItem(oldItem);
    }
    const searchKeyUp = (state)=>{
        setSearchKey(state.inputRef.current.value);
    }
    const state_search = (item,key)=>{
        return stateSearch(item,key);
    }
    const onResetItem = ()=>{
        resetData({item:{...newItem}});
        setEdit(null); setCode(null);
    }
    
    const onShowSetting = (p)=>{
        setSettingId(deepclone({i:p.i,item:p.item,prop_key:[],prop_value:[]}));
    }
    const onHideSetting = ()=>{
        setSettingId({i:"all",prop_key:[],prop_value:[]});  
    }
    const lsItemKeyup = (res,fprop)=>{
        let currSetting = deepclone(settingId);
        currSetting[fprop.columnname]=(res.state.remove)?[...res.state.tags]:(res.state.update)?[...res.state.tags]:[...res.state.tags,res.state.value];
        setSettingId(currSetting);
    }
    const lsEditItemKeyup = (res,fprop)=>{
        let oldvalue = deepclone(value);
        oldvalue.list[fprop.refIndex].setting[fprop.columnname] = (res.state.remove)?[...res.state.tags]:(res.state.update)?[...res.state.tags]:[...res.state.tags,res.state.value];
        updateValue({value:oldvalue});
        setValue(oldvalue);
    }
    const lsItemValueup = (res,fprop)=>{
        let currSetting = deepclone(settingId);
        currSetting[fprop.columnname]=(res.state.remove)?[...res.state.tags]:(res.state.update)?[...res.state.tags]:[...res.state.tags,res.state.value];
        setSettingId(currSetting);
    }
    const onAddSettingProperty = ()=>{
        let oldvalue = deepclone(value);
        let currSetting = deepclone(settingId);
        currSetting.item.setting = currSetting.item.setting||{};
        currSetting.item.setting[currSetting.prop_key[0]] = currSetting.prop_value;
        oldvalue.list[currSetting.i] = currSetting.item;
        updateValue({value:oldvalue});
        setValue(oldvalue);
        currSetting.prop_key = null, currSetting.prop_value = [], setSettingId(currSetting);
        newPromise({},10).then(()=>{
            currSetting.prop_key = []; setSettingId(currSetting);
        })
    }
    const onRemoveSettingProperty = (p)=>{
        let oldvalue = deepclone(value), setting = {}, currentItem = deepclone(p.item);
        for(var i in currentItem.setting){
            var iobj=currentItem.setting[i];
            if(p.key!=i){ setting[i] =iobj }
        }
        currentItem.setting = setting;
        oldvalue.list[p.i] = currentItem;
        updateValue({value:oldvalue});
        setValue(oldvalue);
    }
    const onCodeKeyChange = (state)=>{
        let value = state.value;
        if(!isEmpty(value)){
            let jsonit = strTojson(value);
            setItem(jsonit);
        }
    }
    const onUpdateCode = ()=>{
        let oldvalue = deepclone(value),currentItem = deepclone(item);
        oldvalue.list[code] = currentItem;
        setCode(null); setEdit(null);
        updateValue({value:oldvalue});
        setValue(oldvalue);
        onResetItem();
    }
    const newJsonCodeTemplate=(p)=>{
        let jsonstr = (JSON.stringify(p.view_at, null, 2) );
        return (
            <React.Fragment>
                {
                    (p.view_at) && (
                        <div className="pad10">
                            <FormField input={{ type: "textarea", className: "form-control", placeholder: "Key", name: "code"}} columnname="code" defaultValue={jsonstr} onKeyUp={onCodeKeyChange} onChange={onCodeKeyChange} onBlur={onCodeKeyChange} formgroupClass="no-mb" />
                            <div className="flex wrap padcell">
                                <div><a href={null} className="button btn-default b1x radius pad10" onClick={onUpdateCode}><span className="spantxt">Update</span></a></div>
                                <div><a href={null} className="button btn-default b1x radius pad10" onClick={onResetItem}><span className="spantxt">Close</span></a></div>
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
    const newFormTemplate=(p)=>{
        return (
            <React.Fragment>
                <div className="form-group pad10">
                    {
                        (p.view_at ) && (
                            <React.Fragment>
                                <div className="">
                                    <div className="flex wrap padcell">
                                        {
                                            (canDeletePermission) && (
                                                <div className="flex-2 smd-4 xs-12">
                                                    <div>
                                                        <span className=" shorttext ">{item.key||"New key"}</span>
                                                        <FormField input={{ type: "text", className: "form-control", placeholder: "Key", name: "key"}} columnname="key" onKeyupFormat={["firstCharAlphaOnly","underscore_word_num","lowerCase"]} onBlurFormat={["firstCharAlphaOnly","lastCharAlphaOnly"]} defaultValue={item.key} onKeyUp={onKeyChange} onChange={onKeyChange} onBlur={onKeyChange} formgroupClass="no-mb" />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="flex-10 smd-8 xs-12">
                                            {
                                                item.value.map((val,val_i)=>{
                                                    let refs_keyname = ["new",val_i,"key"].join("_");
                                                    return (
                                                        <div key={val_i}>
                                                            <div className="flex wrap grow padcell">
                                                                {
                                                                    Object.keys(val).map((key_val,key_val_i)=>{
                                                                        let has_removebtn = (key_val!="value");
                                                                        let tagvalue = val[key_val]||[];
                                                                        return(
                                                                            <div className="flex-12" key={key_val_i}>
                                                                                <div className={["relative"].join(" ")}>
                                                                                    <span className=" shorttext ">{key_val}</span>
                                                                                        <FormField input={{ type: "arraytext", className: ["form-control",(has_removebtn)?"padr50":""].join(" "), placeholder: String(key_val).toUpperCase(), name: key_val }} columnname={"pair"} refIndex={val_i} defaultValue={tagvalue} onKeyUp={onValueChange} onChange={onValueChange} onBlur={onValueChange} formgroupClass="no-mb" tagUpDown={true} >
                                                                                        </FormField>
                                                                                    {
                                                                                        (has_removebtn && canDeletePermission)&&(
                                                                                            <div className="abs topright h flex align-items-center">
                                                                                                <a href={null} className="anchor fill grey radius noborder size-xs nolh before-dark" onClick={(e)=>{onValueItemDelete({e,item:val,i:val_i,key:key_val,keyi:key_val_i})}}>
                                                                                                    <span className="relative"><i className="ion-android-remove-circle"></i></span>
                                                                                                </a>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <div className="flex-12">
                                                                    <div className="flex padcell">
                                                                        {
                                                                            (val_i>1||item.value.length!=1 && canDeletePermission) && (
                                                                                <div>
                                                                                    <a href={null} className="anchor fill light radius noborder button-icon-xs" onClick={(e)=>{onDeleteValueSet({e,item:val,i:val_i})}}>
                                                                                        <span className="relative"><i className=" ion-android-remove-circle "></i></span>
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            (val_i==item.value.length-1 && canEditPermission) && (
                                                                                <div className="nodisplay">
                                                                                    <a href={null} className="anchor fill light radius noborder small button-input " onClick={(e)=>{onAddValueSet({e,item:val,i:val_i})}}>
                                                                                        <span className="relative padx5"><i className=" ion-plus-round "></i> <span className="">New Set</span></span>
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            (canDeletePermission) && (
                                                                                <div className="relative">
                                                                                    <div className="flex padcell">
                                                                                        <div>
                                                                                            <span className=" shorttext nodisplay">{"New key addional value"}</span>
                                                                                            <FormField input={{ type: "text", className: "form-control", placeholder: "Key name for new value", name: refs_keyname }} columnname={refs_keyname} refIndex={val_i} onKeyupFormat={["firstCharAlphaOnly","underscore_word_num","lowerCase"]} onBlurFormat={["firstCharAlphaOnly","lastCharAlphaOnly"]} defaultValue={""} onKeyUp={onNewKeyChange} onChange={onNewKeyChange} onBlur={(e)=>{onNewKeyType({e,type:"array"})}} formgroupClass="no-mb"></FormField>
                                                                                        </div>
                                                                                        {
                                                                                            (newKeyName==refs_keyname) && (
                                                                                                <React.Fragment>
                                                                                                    <div>
                                                                                                        <div className="flex wrap padcell">
                                                                                                            <span>
                                                                                                                <a href={null} className="anchor fill light radius noborder button-input small" onClick={(e)=>{onNewKeyType({e,type:"array"})}} title="Add New Key">
                                                                                                                    <span className="relative padx10">{"Add"}</span>
                                                                                                                </a>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            {
                                                                (val_i!=item.value.length-1) && (
                                                                    <div className="divider dashed"></div>
                                                                )||""
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> 
                                    <div className="flex wrap padcell">
                                        {
                                            (!isEmpty(item.key))&&(<div><a href={null} className="button btn-default b1x radius pad10" onClick={onAddKeyPair}><span className="spantxt">{(isEmpty(edit)?"Add Item":"Update Item")}</span></a></div>)
                                        }
                                        {
                                            (!isEmpty(item.key))&&( <div><a href={null} className="button btn-default b1x radius pad10" onClick={onResetItem}><span className="spantxt">{(edit==null)?"Reset":"Close"}</span></a></div>)
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </React.Fragment>
        )
    }
    
    return (
        <React.Fragment>
            <div>
                {
                    (edit==null) && (
                        <React.Fragment>
                            {newFormTemplate({"view_at":(item)})}
                        </React.Fragment>
                    )
                }
                {
                    (value && value.list && value.list.length)&&(
                        <React.Fragment>
                            <div className="divider"></div>
                            {
                                (settingId.i=="all") && (
                                    <div className="">
                                        <FormField input={{ type: "text", className: "form-control", placeholder: "Search", name: "search" }} removeValue={true} columnname="search" defaultValue={searchkey} onKeyUp={searchKeyUp} ></FormField>
                                    </div>
                                )
                            }
                            <div className="oddeven-row">
                                {
                                    value.list.map((vitem,vi)=>{
                                        let vitem_value = vitem, _true =true;
                                        return (
                                            <React.Fragment key={vi}>
                                                {
                                                    (edit==null || edit!=vi)&&(
                                                        <React.Fragment>
                                                            <div className={[(settingId.i==vi||settingId.i=="all"||(_true))?"":"nodisplay"].join(" ")}>
                                                                <div className={["flex padcell wrap grow justify-between",((state_search(vitem_value,searchkey))?"":"nodisplay")].join(" ")}>
                                                                    <div className="flex-8">
                                                                        <div className="flex padcell wrap">
                                                                            <div>
                                                                                <div className="flex">
                                                                                    <div>
                                                                                        <a href={null} className={["anchor fill light circle relative text-center pad5 margr5 sq30x"]} onClick={()=>{orderMoveUp({item:vitem_value,i:vi})}} >
                                                                                            <span className="relative">
                                                                                                <i className="ion-arrow-up-c"></i>
                                                                                            </span>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="relative ellipsis block"> <span className="padx5 pady1 inlineblock bg theme"></span> {vitem_value.key}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex wrap">
                                                                                    {
                                                                                        vitem_value.value.map((value_item, vi_i)=>{
                                                                                            return (
                                                                                                <React.Fragment key={vi_i}>
                                                                                                    <div className="flex wrap padcell">
                                                                                                        {
                                                                                                            Object.keys(value_item).map((val,val_i)=>{
                                                                                                                return (
                                                                                                                    <div key={val_i}>
                                                                                                                        <React.Fragment>
                                                                                                                            {
                                                                                                                                value_item[val].map((sub_item,sub_i)=>{
                                                                                                                                    return (
                                                                                                                                        <React.Fragment key={sub_i}>
                                                                                                                                            <span className="inlineblock bg grey2 radius pad5 margb5 wbreakall">{sub_item}</span>
                                                                                                                                        </React.Fragment>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </React.Fragment>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="flex wrap padcell justify-end">
                                                                            {
                                                                                (canDeletePermission) && (
                                                                                    <div>
                                                                                        {
                                                                                            (settingId.i!=vi) && (
                                                                                                <a href={null} className="anchor fill light radius noborder button-icon-xs" onClick={(e)=>{onShowSetting({e,item:vitem_value,i:vi})}}>
                                                                                                    <span className="relative"><i className="ion-ios-gear"></i></span>
                                                                                                </a>
                                                                                            )||(
                                                                                                <a href={null} className="anchor fill light radius noborder button-icon-xs" onClick={(e)=>{onHideSetting({e,item:vitem_value,i:vi})}}>
                                                                                                    <span className="relative"><i className=" ion-close-circled "></i></span>
                                                                                                </a>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                (settingId.i!=vi) && (
                                                                                    <React.Fragment>
                                                                                        {
                                                                                            (canEditPermission) && (
                                                                                                <React.Fragment>
                                                                                                    <div>
                                                                                                        <a href={null} className="anchor fill light radius noborder button-icon-xs" onClick={(e)=>{onJsonCode({e,item:vitem_value,i:vi})}}>
                                                                                                            <span className="relative"><i className="ion-code-working"></i></span>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <a href={null} className="anchor fill light radius noborder button-icon-xs" onClick={(e)=>{onEditData({e,item:vitem_value,i:vi})}}>
                                                                                                            <span className="relative"><i className="ion-edit"></i></span>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <a href={null} className="anchor fill light radius noborder button-icon-xs" onClick={(e)=>{onCloneData({e,item:vitem_value,i:vi})}}>
                                                                                                            <span className="relative"><i className="ion-ios-browsers-outline"></i></span>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            (canDeletePermission) && (
                                                                                                <div>
                                                                                                    {
                                                                                                        (!isAny(JSON.stringify((vitem_value)),/nodeletebtn/i)) && (
                                                                                                            <a href={null} className="anchor fill light radius noborder button-icon-xs" onClick={(e)=>{onDeleteData({e,item:vitem_value,i:vi})}}>
                                                                                                                <span className="relative"><i className="ion-ios-trash"></i></span>
                                                                                                            </a>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (settingId.i==vi) && (
                                                                        <React.Fragment>
                                                                            <div className="border default pad10">
                                                                                <div className="flex wrap padcell grow">
                                                                                    {
                                                                                        (vitem_value.setting) && (
                                                                                            Object.keys(vitem_value.setting).map((setg_key,setg_i)=>{
                                                                                                let setg_val = vitem_value.setting[setg_key];
                                                                                                return(
                                                                                                    <div key={setg_i}>
                                                                                                        <div className="flex padcell">
                                                                                                            <span className="relative">
                                                                                                                <p><a href={null} className="anchor theme" onClick={(e)=>{onRemoveSettingProperty({e,item:vitem_value,i:vi,key:setg_key})}}>
                                                                                                                        <span className="relative"><i className=" ion-close-circled"></i> {setg_key} </span>
                                                                                                                    </a></p>
                                                                                                                <FormField input={{ type: "arraytext", className: "form-control", placeholder: setg_key, name: setg_key }} refIndex={vi} columnname={setg_key} defaultValue={setg_val||[]} callback={lsEditItemKeyup}  tagUpDown={true}>
                                                                                                                </FormField>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                    <div className="flex-100">
                                                                                        <div className="flex wrap padcell align-items-end">
                                                                                            <div>
                                                                                                {
                                                                                                    (settingId.prop_key) && (
                                                                                                        <FormField input={{ type: "arraytext", className: "form-control", placeholder: "New key", name: "prop_key" }} columnname={"prop_key"} callback={lsItemKeyup} onKeyupFormat={["firstCharAlphaOnly","underscore_word_comma","lowerCase"]} onBlurFormat={["firstCharAlphaOnly","lastCharAlphaOnly"]} label="New key" defaultValue={settingId.prop_key} limit="1"  tagUpDown={true} ></FormField>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                (settingId.prop_key && settingId.prop_key.length!=0)&&(
                                                                                                <React.Fragment>
                                                                                                    <div>
                                                                                                        <FormField input={{ type: "arraytext", className: "form-control", placeholder: "Property Value", name: "prop_value" }} columnname={"prop_value"} defaultValue={settingId.prop_value||[]} callback={lsItemValueup}  tagUpDown={true} ></FormField>
                                                                                                    </div>
                                                                                                    <div><a href={null} className="button btn-default b1x radius pad10" onClick={onAddSettingProperty}><span className="spantxt">{(isEmpty(edit)?"Add Item":"Update Item")}</span></a></div>
                                                                                                </React.Fragment>   
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                                <div className={["divider dashed",((state_search(vitem_value,searchkey))?"":"nodisplay")].join(" ")}></div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                                {
                                                    (edit==vi)&&(
                                                        <React.Fragment>
                                                            <div className="mild-shadow bg-white pull-x margy10">
                                                                {
                                                                    (!isNull(code)) && (
                                                                        <React.Fragment>
                                                                            {newJsonCodeTemplate({"view_at":(item)})}
                                                                        </React.Fragment>
                                                                    )||(
                                                                        <React.Fragment>
                                                                            {newFormTemplate({"view_at":(item)})}
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </React.Fragment>
                    )||""
                }  
            </div>
        </React.Fragment>
    )
}