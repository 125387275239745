import React, { Component } from 'react'

// import PropTypes from 'prop-types'
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import PG_PublicLayout from './pages/PG_PublicLayout';

class NotFoundPage extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
    }
    render() {
        return (
            <PG_PublicLayout>
                <div className="flex wrap pad grow mnvh align-items-center">
                    <div className="flex-12">
                        <div className="text-center">
                            <h1 className="h1">404</h1>
                            <p>Page not found!</p>
                        </div>
                    </div>
                </div>
            </PG_PublicLayout>
        )
    }
}

NotFoundPage.propTypes = {
};

function mapStateToProps() {
    return {
    };
}

function mapDispatchToProps() {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotFoundPage);