import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navbar from '../common/navbar/Navbar';
import Topbar from '../common/topbar/Topbar';
import ProgressBar from '../common/progressbar/ProgressBar';

import * as TBActions from '../common/topbar/TopbarAction';
import * as NavbarAction from '../common/navbar/NavbarAction';
import { authenticate, isMobile } from '../helpers/helpers';

class PG_Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile : isMobile()
        }
    }
    componentDidMount(){
        authenticate({navbarAction:this.props.NavbarAction});
    }
    
    render() {
        return (
            <React.Fragment>
                <div className={["app mnvh", this.props.topbar.sidenav ? "admin-menu-open" : "", "mobile-"+this.state.isMobile ].join(" ")}>
                    <Navbar />
                    <div className="admin-page">
                        <Topbar />
                        <ProgressBar />
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


PG_Layout.propTypes = {
    topbar: PropTypes.object,
    TBActions: PropTypes.object,
    NavbarAction: PropTypes.object,
    children: PropTypes.object
};

function mapStateToProps(state) {
    return {
        topbar: state.TopbarR,
        navbar : state.NavbarR
    };
}

function mapDispatchToProps(dispatch) {
    return {
        TBActions: bindActionCreators(TBActions, dispatch),
        NavbarAction: bindActionCreators(NavbarAction, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_Layout);