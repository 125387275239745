export const menulist = {
    "dashboard":{
        "label": "Dashboard",
        "url": "/dashboard"
    },
    "sitemenu":{
        "label": "Site Menu",
        "url": "/sitemenu",
        "table" : "sitemenu"
    },
    "image":{
        "label": "Image Collection",
        "url": "/image",
        "table" : "image_collection"
    },
    "media":{
        "label": "Media",
        "url": "/media",
        "table" : "media"
    },
    "section-content":{
        "label": "Section Content",
        "url": "/section-content",
        "table" : "section_content"
    },
    "event":{
        "label": "Event",
        "url": "/event",
        "table" : "event"
    },
    "publication":{
        "label": "Publication",
        "url": "/publication",
        "table" : "publication"
    },
    "pages":{
        "label": "Pages",
        "url": "/pages",
        "table" : "pages"
    },
    "blog":{
        "label": "Blog",
        "url": "/blog",
        "table" : "blog"
    },
    "user":{
        "label": "User",
        "url": "/user"
    },
    "webeditor":{
        "key" : "webeditor",
        "label": "Webeditor",
        "url": "/webeditor"
    }
}
const categorized = {
    "admin" : ["dashboard","sitemenu","image","media","section-content","event","publication","blog","pages","user"],
    "user" : ["dashboard","sitemenu","image","media","section-content","event","publication","blog","pages"],
    "default" : ["dashboard"],
}
export const getMenus = function(p){
    let newMenu = [], list = categorized[p.usertype];
    for(var i in list){
        if(menulist[list[i]]){
            newMenu.push(menulist[list[i]]);
        }    
    }
    return newMenu;
}