import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { arraynoempty, array_to_stringjoin, deepclone, hideProgressBar, isAny, isApiErr, isEmpty, isLength, navigateTo, newPromise, quilToolbarOptions, state_search, stringify, strTojson, isNull } from '../../helpers/helpers';
import { ProductPhotoFetch } from '../../common/productphotofetch/ProductPhotoFetch';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ImageUploader from 'react-images-upload';
import { FormField } from '../../common/formfield/FormField';
import config from '../../../config/configApp';
import { SectionDataFnComp } from '../../common/section_data/SectionData';

DetailComp.propTypes = {
    add : PropTypes.bool,
    callAction : PropTypes.any,
    pbAction : PropTypes.any,
    callback : PropTypes.func,
    user : PropTypes.object,
    itemDetail : PropTypes.object,

}
export function DetailComp({add,itemDetail, callAction,callback,user, pbAction}){
    const videoShow = false; const uploadreset = []; const tablename = "publication";
    const [detail,setDetail] = useState(null);
    const [currentTab,setCurrentTab] = useState(null);
    const [showupload,setShowupload] = useState(false);
    const [editorToolbar,setEditorToolbar] = useState();
    const setRenderInfo = (p)=>{
        setDetail(p.detail);
        setCurrentTab(p.currentTab);
        setEditorToolbar({
            toolbar: quilToolbarOptions()
        });
        setShowupload(itemDetail);
    }
    const getInfo = ()=>{
        if(!detail){
            setCurrentTab(null);
            setRenderInfo({detail:renderDetail(itemDetail),currentTab:"general"});
        }
    }
    const renderDetail = (_detail)=>{
        let pvd = _detail;
        pvd.content = String(pvd.content || "").replace(/!slash!/g, "/");
        pvd.category = String(pvd.category).split(",");
        pvd.tag = String(pvd.tag).split(",");
        pvd.photo = strTojson(pvd.photo);
        pvd.section_content = strTojson(pvd.section_content);
        return pvd;
    }
    const this_input_save = (p)=>(field)=>{ 
        let pvd = detail;
        pvd[p.name] = field.value;
        setDetail(pvd);
    }
    const this_onEditorChange = (p)=>(event)=>{ 
        let pvd = deepclone(detail); 
        pvd[p.name] = event;
        setDetail(pvd);
    }
    const this_photo_remove = (p)=>()=>{
        let pvd = deepclone(detail);
        if (pvd.photo[p.i]) {
            pvd.photo.splice(p.i, 1);
            setDetail(pvd);
        }
    }
    const this_feature_img_change = (_files, pictures)=>{ 
        if (pictures.length) {
            let uploadblefile = [];
            for (var i in _files) {
                let ufile = deepclone(_files[i]);
                ufile.dataURL = pictures[i];
                uploadblefile.push(ufile);
            }
            setShowupload(false);
            pbAction.enable({ show: true, text: "Uploading.." });
            let prevFiles = deepclone(detail.photo || []);
            callAction.exec({ action: "uploads", tablename, rowid: detail.id, reducerName: "publication_update", columnname: "photo", files: uploadblefile, prevFiles }, "products/").then((res) => {
                let hideOption = {}; setShowupload(true);
                if (isApiErr(res)) {
                    pbAction.enable({ text: res.message || "Record not updated." });
                } else {
                    hideOption.duration = 1000;
                    pbAction.enable({ text: "Successfully updated.." });
                    if (res.payload.updated) {
                        setDetail({...detail,...{photo:[...res.payload.updated]}});
                    }
                }
                hideProgressBar({PBActions:pbAction}, hideOption);
            });
        }
    }
    const this_publish = ()=>{
        let status_values = config.data.status_values, columns = { status: status_values[detail.status] || "draft" }, rowid = detail.id;
        pbAction.enable({ show: true, text: "Updating.." });
        callAction.exec({ action: "update", tablename , rowid, reducerName: "publication_update", columns }, "products/").then((res) => {
            let hideOption={};
            if (isApiErr(res)) {
                pbAction.enable({ text: res.message || "Record not updated." });
            } else {
                let prev_detail = detail;
                prev_detail.status = columns.status;
                setDetail(prev_detail);
                hideOption.duration=1000;
                pbAction.enable({ text: "Successfully updated.." });
            }
            hideProgressBar({PBActions:pbAction},hideOption);
        }, (err) => {
            pbAction.enable({ text: err });
            hideProgressBar({PBActions:pbAction});
        });
    }
    const this_delete = ()=>{
        pbAction.enable({ show: true, text: "Deleting.." });
        callAction.exec({ action: "delete", tablename , rowid:detail.id, force_delete: true, reducerName: "publication_delete" }, "products/").then((res) => {
            let hideOption={};
            if (isApiErr(res)) {
                pbAction.enable({ text: res.message || "Record not deleted." });
            } else {
                setDetail(null);
                hideOption.duration=1000;
                pbAction.enable({ text: "Record deleted.." });
                navigateTo({url:"/publication"});
            }
            hideProgressBar({PBActions:pbAction},hideOption);
        }, (err) => {
            pbAction.enable({ text: err });
            hideProgressBar({PBActions:pbAction});
        });
    }
    const this_add_save = ()=>{
        let { title, description, content, photo,category, tag, section_content,price,currency_type,url,external_url } = detail;
        let columns = { title,description,content,category,tag,price,currency_type,url,external_url };

        if(photo){ columns.photo = photo; }        
        if (section_content) { columns.section_content = JSON.stringify(section_content); }
        
        columns.category = array_to_stringjoin(arraynoempty(category),",");
        columns.tag = array_to_stringjoin(arraynoempty(tag),",");

        columns["user_id"] = user.id;
        columns["content"] = String(content).replace(/\//g, "!slash!");
        let success = (res) => {
            let hideOption ={};
            if (isApiErr(res)) {
                pbAction.enable({ text: res.message || "Record not updated." });
            } else {
                setCurrentTab(null);
                setDetail(null);
                callback({ref:"afterNewItemAdded"});
            }
            hideProgressBar({PBActions:pbAction},hideOption);
        }, err = (err) => {
            pbAction.enable({ text: err });
            hideProgressBar({PBActions:pbAction});
        }
        let formvalid = (isEmpty(title) && isEmpty(description))?false:true;
        if(formvalid){
            pbAction.enable({ show: true, text: "Updating.." });
            callAction.exec({
                action: "insert", tablename , reducerName: "publication_add", columns
            }, "products/").then(success, err);
        }else{
            pbAction.enable({ show: true, text: "Please fill the required field.."});
            hideProgressBar({PBActions:pbAction},{duration:1500})
        }
    }
    const this_edit_save = ()=>{ 
        let { title, description, content,category, tag, section_content,photo,price,currency_type,url,external_url } = detail;
        let columns = { title,description,content,category,tag,price,currency_type,url,external_url };
        columns.category = array_to_stringjoin(arraynoempty(category),",");
        columns.tag = array_to_stringjoin(arraynoempty(tag),",");
        columns["content"] = String(content).replace(/\//g, "!slash!");
        if (section_content) { columns.section_content = stringify(section_content); }
        if (isLength(photo)) { columns.photo = stringify(photo); }else{ columns.photo = "null"}
        columns["user_id"] = user.id;
        pbAction.enable({ show: true, text: "Updating.." });
        callAction.exec({ action: "update", tablename , rowid:detail.id, reducerName: "publication_update", columns }, "products/").then((res) => {
            let hideOption={};
            if (isApiErr(res)) {
                pbAction.enable({ text: res.message || "Record not updated." });
            } else {
                hideOption.duration=1000;
                pbAction.enable({ text: "Successfully updated.." });
            }
            hideProgressBar({PBActions:pbAction},hideOption);
        });
    }
    const this_section_content_data_update = (itemvalue)=>{
        let pvd = detail,delay = newPromise({},1);
        pvd.section_content = itemvalue;
        setDetail(pvd);
        delay.then(()=>{
            this_edit_save();
        });
        return delay;
    }
    getInfo();
    return (
        <React.Fragment>
            <div className="flex wrap pad grow">
                <div>
                    <p className=""> <a title="Publication List" href={null} onClick={()=>navigateTo({url:"/publication"})}>Publication /</a></p>
                    {
                        (detail) && (
                            <h6 className="">{detail.title}</h6>
                        )
                    }
                </div>
            </div>
            <div className="flex-wrap oddeven onhover rowtool-static bshadow-inset my-2">
                {
                    (detail) && (
                        <React.Fragment>
                            <div className="flex-12">
                                <div className={["widget relative"].join(" ")}>
                                    <span className={["bg abs topleft h padl1x", detail.status].join(" ")}></span>
                                    <div className="widget-header flex justify-between align-items-stretch">
                                        <div>
                                            <div className="flex align-items-center">
                                                <div>
                                                    <div className="widget-action-list">
                                                        <ul>
                                                            <li>
                                                                <a className="icon-item" href={null} onClick={() => navigateTo({ url: '/publication' })}><span className="icon-span"> <i className={["ion-arrow-left-c"].join("")}></i> </span></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {
                                                    (detail.photo) && (
                                                        <div className="flex-1">
                                                            <div className="user-photo-label flex fxc align-items-center">
                                                                <label className="nav-photo">
                                                                    <ProductPhotoFetch props={{photo:detail.photo, usethumb : true}}/>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <div className="widget-action-list active-line nowrap">
                                                <ul className="">
                                                    <li className={[(currentTab=='general')?'active':''].join(" ")}>
                                                        <a onClick={()=>setCurrentTab("general")} className="txt ellipsis oh" href={null}>General</a>
                                                    </li>
                                                    <li className={[(currentTab=='content')?'active':''].join(" ")}>
                                                        <a onClick={()=>setCurrentTab("content")} className="txt ellipsis oh"  href={null}>Content</a>
                                                    </li>
                                                    {
                                                        (!add) && (
                                                            <React.Fragment>
                                                                <li className={[(currentTab=='section_content')?'active':''].join(" ")}>
                                                                    <a onClick={()=>setCurrentTab("section_content")} className="txt ellipsis oh"  href={null}>Section Data</a>
                                                                </li>
                                                                <li className={[(currentTab=='media')?'active':''].join(" ")}>
                                                                    <a onClick={()=>setCurrentTab("media")} className="txt ellipsis oh" href={null}>Photo</a>
                                                                </li>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="alignself-end">
                                            <div className="widget-action-list">
                                                <ul className="">
                                                    {
                                                        (!add) && (
                                                            <React.Fragment>
                                                                {
                                                                    (isAny(user.allowed,/update/i))&&(
                                                                        <li>
                                                                            <a href={null} title="Publish/Draft" onClick={this_publish}>
                                                                                <span className="relative padx5"> <i className={[(detail.status == 'draft' ? 'ion-thumbsdown' : 'ion-thumbsup')].join("")}></i> </span> <span className="visible-from-sm">Publish</span>
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                }
                                                                {
                                                                    (isAny(user.allowed,/delete/i))&&(
                                                                        <li>
                                                                            <a href={null} title="Delete" onClick={this_delete}> <span className="relative padx10"> <i className="ion-ios-trash"></i> </span> </a>
                                                                        </li>
                                                                    )
                                                                }
                                                            </React.Fragment>   
                                                        )
                                                    }
                                                    
                                                    {
                                                        (isAny(user.allowed,/update/i))&&(
                                                            <li>
                                                                <a href={null} onClick={(add)?this_add_save:this_edit_save}>
                                                                    <span className="padx10"><i className="ion-android-send"></i> <span className="visible-from-sm">Save</span></span>
                                                                </a>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget-body relative">
                                        <span className={["bg abs topleft w pady2", detail.status].join(" ")}></span>
                                        <h6 className="pad10 hidden-from-sm capitalize">{currentTab}</h6>
                                        {
                                            (currentTab=="general") && (
                                                <React.Fragment>
                                                    <div className="flex wrap pad">
                                                        <div className="flex-6 sm-12">
                                                            <FormField input={{ type: "text", className: "form-control", placeholder: "Title", name: "title" }} isRequired="true" columnname="title" defaultValue={detail.title} label="Title" onChange={this_input_save({name:"title"})} onBlur={this_input_save({name:"title"})} />
                                                            <FormField input={{ type: "textarea", className: "form-control", placeholder: "Description", name: "description" }} isRequired="true" columnname="description" defaultValue={detail.description} label="Description" onChange={this_input_save({name:"description"})} onBlur={this_input_save({name:"description"})} />
                                                        </div>
                                                        <div className="flex-6 sm-12">
                                                            <FormField input={{ type: "arraytext", className: "form-control", placeholder: "Category", name: "category" }} columnname="category" defaultValue={(isNull(detail.video))?[]:detail.video} label="Category" onChange={this_input_save({name:"category"})} limit={1} >
                                                                <p className="h7">You can enter multiple categories</p>
                                                            </FormField>
                                                            <FormField input={{ type: "arraytext", className: "form-control", placeholder: "Tag", name: "tag" }} columnname="tag" defaultValue={(isNull(detail.tag))?[]:detail.tag} label="Tag" onChange={this_input_save({name:"tag"})} limit={30} >
                                                                <p className="h7">You can enter multiple Tags</p>
                                                            </FormField>
                                                        </div>
                                                        <div className="flex-6 sm-12">
                                                            <FormField input={{ type: "text", className: "form-control", placeholder: "Url", name: "url" }} isRequired="true" columnname="url" defaultValue={detail.url} label="url" onKeyupFormat={["firstCharAlphaOnly","hypen_word_num","lowerCase"]} onBlurFormat={["firstCharAlphaOnly"]} onChange={this_input_save({name:"url"})} onBlur={this_input_save({name:"url"})} />
                                                        </div>
                                                        <div className="flex-6 sm-12">
                                                            <FormField input={{ type: "text", className: "form-control", placeholder: "External URL", name: "external_url" }} isRequired="true" columnname="external_url" defaultValue={detail.external_url} label="External URL"  onChange={this_input_save({name:"external_url"})} onBlur={this_input_save({name:"external_url"})} />
                                                        </div>
                                                        <div className="flex-4 sm-6 xs-12">
                                                            <FormField input={{ type: "text", className: "form-control", placeholder: "Price", name: "price" }} columnname="price" defaultValue={detail.price} label="Price" onKeyupFormat={["amountOnly"]} onChange={this_input_save({name:"price"})} onBlur={this_input_save({name:"price"})} />
                                                        </div>
                                                        <div className="flex-4 sm-6 xs-12">
                                                            <FormField input={{ type: "text", className: "form-control", placeholder: "Currency Type", name: "price" }} columnname="currency_type" defaultValue={detail.currency_type} label="Currency Type" onChange={this_input_save({name:"currency_type"})} onBlur={this_input_save({name:"currency_type"})} />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            (currentTab=="content") && (
                                                <React.Fragment>
                                                    <div className="padb10">
                                                        <span className="block lead h7 font-semibold padb10">Content</span>
                                                        <div className="bg white">
                                                            <ReactQuill modules={editorToolbar} theme="snow" defaultValue={detail.content} onChange={this_onEditorChange({name:"content"})} />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            (currentTab=="section_content") && (
                                                <div>
                                                    <SectionDataFnComp stateSearch={state_search} canDeletePermission={isAny(user.allowed,/delete/i)} canEditPermission={isAny(user.allowed,/update/i)} defaultValue={detail.section_content||{list:[]}} callback={this_section_content_data_update}>
                                                    </SectionDataFnComp>
                                                </div>
                                            )||""
                                        }
                                        {
                                            (currentTab=="media") && (
                                                <React.Fragment>
                                                    <div className=" flex grow wrap pad flex-container">
                                                        <div className="flex-12">
                                                            <div className={["relative"].join(" ")}>
                                                                <div className="flex wrap align-items-center pad">
                                                                    {
                                                                        (showupload) && (
                                                                            <div className="flex-12 relative bg white">
                                                                                <ImageUploader name="photo" withIcon={false} withPreview={false} singleImage={false} buttonText='Choose Feature images' onChange={this_feature_img_change} defaultImages={uploadreset||true} imgExtension={['.jpg', '.png', '.gif']} maxFileSize={5242880} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        (detail.photo) && (
                                                                            detail.photo.map((photoitem, i) => {
                                                                                return (
                                                                                    <React.Fragment key={i}>
                                                                                        <div className="">
                                                                                            <div className="relative">
                                                                                                <img height="200px" className="auto mxw" src={[config.apiImgPath + "/" + photoitem.photo].join("")} />
                                                                                                <div className="abs topright pad5">
                                                                                                    <a href={null} className="anchor fill light radius padx5" onClick={this_photo_remove({item:photoitem,i})}>
                                                                                                        <i className="ion-ios-trash padx5"></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (videoShow) && (
                                                                <div className="flex-12">
                                                                    <FormField input={{ type: "arraytext", className: "form-control", placeholder: "Video", name: "video" }} columnname="video" defaultValue={(isNull(detail.tag))?[]:detail.tag} label="Video" onChange={this_input_save} >
                                                                        <p>Please enter youtube video url</p>
                                                                    </FormField>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        <div className="flex wrap grow pad ypad-off">
                                            <div>
                                                {
                                                    (itemDetail) && (
                                                        <div className="">
                                                            <p className="flex wrap grow fxc">
                                                                <span>
                                                                    <span className="block lead h7 font-semibold">Created At </span>
                                                                    {detail.created_at}
                                                                </span>
                                                                <span>
                                                                    <span className="block lead h7 font-semibold">Updated At </span>
                                                                    {detail.updated_at}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )||""
                }
            </div>
        </React.Fragment>
    )
}