import React, { useState, useRef, useEffect } from 'react';

export function TagInput(p) {
    const [limit] = useState(p.limit||1000);
    let locset = {
        updown_arrow : p.updown_arrow||false
    }
    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    const useFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
        return [htmlElRef, setFocus]
    }
    const [inputRef] = useFocus();
    const [tags, setTagsValue] = useState([...p.tags]);
    const [tag, setTagValue] = useState(null);
    useEffect(() => {
        onSave();
        return () => {
        }
    }, [tags, setTagsValue]);
    const onSave = () => {
        if (typeof p.onSave === 'function') {
            p.onSave({ ...p, tags })
        }
    }
    const callback = (value)=>{
        if(typeof p.callback ==="function"){
            p.callback({state:value,inputRef});
        }
    }
    const onkeyup = (e) => {
        let enter = new RegExp(KeyCodes.enter);
        let comma = new RegExp(KeyCodes.comma), value = e.target.value;
        if(typeof p.updateFormatValue==="function"){
            value = p.updateFormatValue(value,"onKeyupFormat");
            inputRef.current.value = value;
        }
        let tagstring = JSON.stringify(tags), tagstring_split = tagstring.split(e.target.value);
        if (String(e.keyCode).match(enter) && e.target.value!="") {
            if (tagstring_split.length==1) {
                setTagsValue([...tags, e.target.value]);
                callback({tags,value:e.target.value});
            }
            inputRef.current.value = '';
        }
        if (String(e.keyCode).match(comma) && e.target.value!="") { 
            if (tagstring_split.length==1) {
                let value = String(e.target.value).replace(/[,]$/, "");
                setTagsValue([...tags, value]);
                callback({tags,value});
            }
            inputRef.current.value = '';
        }
    }
    const onEditKeyup = (e) => {
        let enter = new RegExp(KeyCodes.enter);
        let comma = new RegExp(KeyCodes.comma), value = e.target.value;
        if(typeof p.updateFormatValue==="function"){
            value = p.updateFormatValue(value,"onKeyupFormat");
            inputRef.current.value = value;
        }
        if (String(e.keyCode).match(enter) && e.target.value!="") {
            tags.splice(tag,1,e.target.value);
            setTagsValue([...tags]);
            setTagValue(null);
            inputRef.current.value = '';
            callback({tags,update:true});
        }
        if (String(e.keyCode).match(comma) && e.target.value!="") { 
            let value = String(e.target.value).replace(/[,]$/, "");
            tags.splice(tag,1,value);
            setTagsValue([...tags]);
            setTagValue(null);
            callback({tags,update:true});
            inputRef.current.value = '';

        }
    }
    const onItemUp = (p) => {
        let value = tags.splice(p.i,1);
        tags.splice(p.i-1, 0,value.shift());
        setTagsValue([...tags]); onSave();
    }
    const onItemEdit = (p) => {
        setTagValue(p.i);
    }
    const onDelete = (p) => {
        let value = tags[p.i];
        tags.splice(p.i, 1);
        setTagsValue([...tags]); onSave();
        callback({tags,value,remove:true});
    }
    return (
        <div className="tag-input-group oh">
            {
                (tags) &&
                (
                    <React.Fragment>
                        {
                            ((tags.length!=limit)) && (
                                <div className="tag-item tag-input">
                                    <input className="form-control" placeholder={p.placeholder || 'Type and press (enter)..'} type="text" onKeyUp={onkeyup} defaultValue={''} ref={inputRef} />
                                </div>
                            )
                        }
                        {
                            (tags.length)&&( 
                                tags.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>

                                            {
                                                (tag!=null && i==tag) && (
                                                    <React.Fragment>
                                                        <input className="form-control" placeholder={p.placeholder || 'Type and press (enter)..'} type="text" onKeyUp={onEditKeyup} defaultValue={tags[i]||''} ref={inputRef} />
                                                    </React.Fragment>
                                                )||(
                                                    (item!="") && (
                                                        <div className="tag-item">
                                                            <label className="tag-pill">
                                                                <span className="flex align-items-stretch h">
                                                                    {
                                                                        (locset.updown_arrow && i) && (
                                                                            <span>
                                                                                <span className="tag-btn flex align-items-center grow" onClick={(e) => { onItemUp({ e, item, i }) }}>
                                                                                    <i className="ion-arrow-left-c"></i>
                                                                                </span>
                                                                            </span>
                                                                        )||""
                                                                    }
                                                                    <span>
                                                                        <span className="tag-pill-txt"  onClick={(e) => { onItemEdit({ e, item, i }) }}>{item}</span>
                                                                    </span>
                                                                    <span>
                                                                        <span className="tag-btn flex align-items-center grow" onClick={(e) => { onDelete({ e, item, i }) }}>
                                                                            <i className="ion-close"></i>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    )
                                                ) 
                                            }
                                        </React.Fragment>
                                    )
                                })
                            )||""
                        }
                    </React.Fragment>
                )
            }
        </div>
    )
}