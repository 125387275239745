import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoginForm from '../login/LoginForm';
import LoginSetup from '../login/LoginSetup';
import * as Actions from '../calls/CallsAction';

import * as PBActions from '../common/progressbar/ProgressBarAction';

import * as NavbarActions from '../common/navbar/NavbarAction';

import { navigateTo, hideProgressBar, setLS, isEmpty, deepclone, isApiErr, encrypt } from '../helpers/helpers';
import PG_PublicLayout from '../pages/PG_PublicLayout';
import config from '../../config/configApp';
import { userModel } from '../pages/user/PG_User_Model';

class HomePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      navbar: {
        "type": "app"
      },
      setup: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSetup = this.handleSetup.bind(this);
  }
  
  handleSubmit(values) {
    const { email, password } = values;
    if (email && password) {
      this.props.PBActions.enable({ show: true, text: "Authenticating.." });
      this.props.Actions.exec({
        action: "account", tablename: "user", reducerName: "user_log", email, password,
        fetchcolumn : ["id","name","email","verify","category","photo","control","allowed","status","dob","description","role"]
      }, "authenticate/").then((res) => {
        if (isApiErr(res)) {
          this.props.PBActions.enable({ text: res.message || "Sorry, Unable to login. Please try again later." });
        } else {
          let user = {...res.payload.user} || {};
          user.timeout = new Date();
          user.timeout.setHours(user.timeout.getHours()+config.appinfo.timeout);
          this.props.NavbarActions.update({ user: user });
          setLS({ key: "logger", value: { user: encrypt(user,true) } }).then(() => {
            this.props.PBActions.enable({ text: "Successfully logged.." });
            navigateTo({ url: "/dashboard" });
          });
        }
        hideProgressBar(this.props, { duration: 15000 });
      }, () => {
        this.props.PBActions.enable({ text: "Sorry, Unable to login. Please try again later." });
        hideProgressBar(this.props);
      });
    } else {
      this.props.PBActions.enable({ text: "Sorry, Wrong input provided." });
      hideProgressBar(this.props);
    }
  }
  handleSetup(values) {
    const { email, password, name } = values;
    let setupData = {
      action: "create", tablename: "user",
      "fields": userModel.userTableFields
    }
    if (!isEmpty(email) && !isEmpty(password)) {
      this.props.PBActions.enable({ show: true, text: "Please wait, running setup.." });
      setupData.columns = deepclone({ ...config.data.newUser, ...{ "name": (name || "Super Man"), email, password, "control": "Admin", "allowed": "create,update,delete", "verify": "y", "category": "Super Admin","user_id":1 } });
      this.props.Actions.exec(setupData, "authenticate/").then((res) => {
        if (isApiErr(res)) {
          this.props.PBActions.enable({ text: res.message || "Sorry, Unable to run setup. Please try again later." });
          hideProgressBar(this.props, { duration: 15000 });
        } else {
          this.setState({ setup: "login" });
          hideProgressBar(this.props, { duration: 1 });
        }
      }, () => {
        this.props.PBActions.enable({ text: "Sorry, setup incompleted. Please contact your adminstrator." });
        hideProgressBar(this.props);
      });
    } else {
      this.props.PBActions.enable({ text: "Sorry, Wrong input provided." });
      hideProgressBar(this.props);
    }
  }

  verifySetup() {
    let user_input = { action: "overview", reducerName: "user_setup", querys: "SELECT COUNT(*) AS total_user FROM user" };
    this.props.Actions.exec(user_input, "products/").then((res) => {
      if (isApiErr(res)) {
        this.setState({ setup: "setup" });
      } else {
        this.setState({ setup: "login" });
      }
    }, () => {
      this.props.PBActions.enable({ text: "Sorry, Unable run application. Please try again later/ contact administrator." });
      hideProgressBar(this.props);
    });
  }

  componentDidMount() {
    this.props.NavbarActions.update(config.data.logReset);
    setLS({ key: "logger", value: {} });
    this.verifySetup();
  }

  render() {
    return (
      <PG_PublicLayout>
        <div className="flex fxc align-items-center justify-center">
          <div className="flex-4 sm-6 xsm-8 xs-12">
            <div className="widget pad30">
              {
                (this.state.setup == "login") && (
                  <React.Fragment>
                    <h5 className="font-semibold text-center uppercase lead h6 margb30">Login</h5>
                    <LoginForm onSubmit={this.handleSubmit} onEnter={true} />
                    <div className="flex grow wrap">
                      <div>
                        <a href={null} className="anchor theme underline" onClick={()=>navigateTo({url:"/forgotpassword"})}>
                          Forgot Password ?
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
              {
                (this.state.setup == "setup") && (
                  <React.Fragment>
                    <h5 className="font-semibold text-center uppercase lead h6 margb30">Portal Setup</h5>
                    <LoginSetup onSubmit={this.handleSetup} />
                  </React.Fragment>
                )
              }
            </div>
          </div>
        </div>
      </PG_PublicLayout>
    )
  }
}

HomePage.propTypes = {
  Actions: PropTypes.object.isRequired,
  NavbarActions: PropTypes.object.isRequired,
  navbar: PropTypes.object,
  PBActions: PropTypes.object.isRequired,
  pb: PropTypes.object
};

function mapStateToProps(state) {
  return {
    navbar: state.NavbarR
  };
}

function mapDispatchToProps(dispatch) {
  return {
    Actions: bindActionCreators(Actions, dispatch),
    PBActions: bindActionCreators(PBActions, dispatch),
    NavbarActions: bindActionCreators(NavbarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);