import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { history } from '../../store/configureStore';
import { FormField } from '../common/formfield/FormField';
import { deepclone, isEmpty } from '../helpers/helpers';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {
        email: {
          value: ""
        },
        password: {
          value: ""
        }
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  goto(url) {
    history.push(url);
  }
  handleChange(s, p) {
    let login = deepclone(this.state.login);
    login[p.input.name].value = s.value;
    this.setState({ login });
  }
  handleBlur(s, p) {
    let login = deepclone(this.state.login);
    login[p.input.name].value = s.value;
    this.setState({ login });
  }
  handleSubmit(){
    let {email,password} = this.state.login; 
    this.props.onSubmit({email:email.value,password:password.value});
  }
  handleKeyUp(s,p){
    let login = deepclone(this.state.login);
    login[p.input.name].value = s.value;
    this.setState({ login });

    let {email,password} = this.state.login, isvalid = (isEmpty(email) && isEmpty(password))?false:true; 
    let isEntered = String(s.e.keyCode).match(/13/);
    if(isvalid && this.props.onEnter && isEntered){
      this.handleSubmit();
    }
  }
  render() {
    let { email, password } = deepclone(this.state.login);
    return (
      <React.Fragment>
        <div className="form-group">
          <FormField input={{ type: "text", className: "form-control", placeholder: "Email", name: "email", autoFocus:true }} defaultValue={email.value} label="Email" onChange={this.handleChange} onBlur={this.handleBlur} onKeyUp={this.handleKeyUp} />
        </div>
        <div className="form-group">
          <FormField input={{ type: "password", className: "form-control", placeholder: "Password", name: "password" }} defaultValue={password.value} label="Password" onChange={this.handleChange} onBlur={this.handleBlur} onKeyUp={this.handleKeyUp} />
        </div>
        <div className="form-group text-right">
          <button type="button" className="anchor fill theme noborder size-xs radius capitalize" onClick={this.handleSubmit}>
            <span className="relative padx20">Get In</span>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  onEnter: PropTypes.bool
};

export default LoginForm;