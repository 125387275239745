import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';

import Navbar from '../common/navbar/Navbar';
import ProgressBar from '../common/progressbar/ProgressBar';
import { isMobile } from '../helpers/helpers';

class PG_PublicLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile : isMobile()
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={["app mnvh", "mobile-"+this.state.isMobile].join(" ")}>
                    <Navbar />
                    <ProgressBar />
                    <div className="app-page grow flex fxc align-items-center justify-center h">
                        <div className="flex-12">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


PG_PublicLayout.propTypes = {
    children:PropTypes.object
};

function mapStateToProps() {
    return {};
}

function mapDispatchToProps() {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_PublicLayout);