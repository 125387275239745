import React from 'react';
import config from '../../../config/configApp';
import PropTypes from 'prop-types'

ProductPhotoFetch.propTypes = {
    props : PropTypes.any,
    photo : PropTypes.any,
    className : PropTypes.any,
}

export function ProductPhotoFetch({props}){
    let photoobj = {
        data : [{photo:"products.png",dummy:true}],
        photo : ""
    };
    const photojson = (typeof props.photo == "string")?JSON.parse(props.photo):props.photo;
    photoobj.photo = "assets/images/"+photoobj.data[0].photo;
    if(typeof photojson === "object"){
        photoobj.data = photojson;
        if(photoobj.data && photoobj.data.length){
            photoobj.photo = config.apiImgPath+photoobj.data[0].photo;
        }
    }
    return (
        <span className={["bg light background", props.className||""].join(" ")} style={{"backgroundImage":["url(",photoobj.photo,")"].join("")}}></span>
    )
}

