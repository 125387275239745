import { topbarActionType } from './TopbarActionType';
import { newPromise } from '../../helpers/helpers';

export function update(payload) {
    return dispatch => {
        dispatch(update(payload));
        return newPromise({});
    };

    function update(payload) { return { type: topbarActionType.update, payload } }
}

