import React, { Component, Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NavbarActions from '../../common/navbar/NavbarAction';

import { history } from '../../../store/configureStore';
import PG_Layout from '../PG_Layout';
import { isAdmin } from '../../helpers/helpers';

import { infoblock_template } from '../../common/template/template';
const PG_User_List = React.lazy(() => import('./PG_User_List'));
const PG_User_View = React.lazy(() => import('./PG_User_View'));

class PG_User extends Component {
    constructor(props) {
        super(props)
        this.state = { }
    }

    componentDidMount() {
        this.props.NavbarActions.update({ type: "admin", "current_page": "/user" });
    }
    render() {
        const isDetail = (String(history.location.pathname).match(/user\/[0-9]{0,}/));
        const canListShow = isAdmin(this.props.navbar.user) && !isDetail;
        return (
            <PG_Layout>
                <div className="desktop"> 
                    {
                        (canListShow) && (
                            <React.Fragment>
                                <Suspense fallback={infoblock_template({ theme : "bg-grey1 color-theme", contentalign:"flex justify-center text-center pad20","children" : "Loading information..." })}>
                                    <PG_User_List/>
                                </Suspense>
                            </React.Fragment>
                        )
                    }
                    {
                        (isDetail) && (
                            <React.Fragment>
                                <Suspense fallback={infoblock_template({ theme : "bg-grey1 color-theme", contentalign:"flex justify-center text-center pad20","children" : "Loading information..." })}>
                                    <PG_User_View/>
                                </Suspense>
                            </React.Fragment>
                        )
                    }
                </div>    
            </PG_Layout>
        )
    }
}

PG_User.propTypes = {
    NavbarActions: PropTypes.object.isRequired,
    navbar : PropTypes.object
};

function mapStateToProps(state) {
    return {
        navbar : state.NavbarR
    };
}

function mapDispatchToProps(dispatch) {
    return {
        NavbarActions: bindActionCreators(NavbarActions, dispatch),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_User);