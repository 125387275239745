import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NavbarActions from '../../common/navbar/NavbarAction';
import * as PBActions from '../../common/progressbar/ProgressBarAction';
import * as Actions from '../../calls/CallsAction';
import { deepclone, hideProgressBar, isAny, isApiErr, navigateTo, newPromise, isLength, bindAS } from '../../helpers/helpers';
import PG_Layout from '../PG_Layout';
import { publicationModel } from './Model';
import { List } from './List';
import { DetailComp } from './Detail';
import config from '../../../config/configApp';

class PG_Publication extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            searchkey : "", searchMatchId:null, list : null, pager : { current : 0, perpage : config.data.listPerPage }, loadmore : false,
            add : false,
            widget : {
                title : "Publication List"
            }, 
            params:{ref:null},
            detail : null
        }
        bindAS(this,["stateCallback","onloadmore"]);
    }
    afterNewItemAdded(){
        this.setState({detail:null});
        newPromise({}).then(()=>{
            navigateTo({url:"/reload/publication"});
        });
    }
    stateCallback(p){
        if(typeof this[p.ref]==="function"){
            this[p.ref](p);
        }
    }
    getList(p){
        this.props.PBActions.enable({ show: true, text: "Fetching records.." });
        this.props.Actions.exec({
            action: "get", tablename: "publication", reducerName: "publication", query : "Select * FROM publication WHERE deleted_at is NULL ORDER BY created_at DESC "+p.limit
        }, "products/").then((res) => {
            let hideOption = {
                duration : 4000
            }
            if (isApiErr(res)) {
                this.props.PBActions.enable({ text: res.message || "Record not found." });
                this.setState({ loadmore:false});
            } else {
                if(res.payload && isLength(res.payload.list)){
                    let {list, pager} = this.state;
                    list = (list||[]).concat(res.payload.list);
                    pager.current = list.length;
                    this.setState({list, loadmore : (res.payload.list.length==pager.perpage), pager, children : "viewList", add:false, detail:null });
                }else{
                    this.props.PBActions.enable({ text: res.message || "Record not found." });
                    this.setState({loadmore : false,  children : "viewList", add:true, detail:null});
                }
            }
            hideProgressBar(this.props,hideOption);
        }, (err) => {
            this.props.PBActions.enable({ text: err });
            hideProgressBar(this.props);
        });
    }
    hasList(){
        return this.state.list;
    }
    getDetail(p){
        let list = this.hasList();
        if(list){
            for(var i in list){
                if(p.rowid==list[i].id){
                    this.setState({children: "viewDetail",detail:deepclone(list[i])});
                    break;
                }
            }
        }
        this.props.PBActions.enable({ show: true, text: "Fetching records.." });
        let data = { action: "get", tablename: "publication", reducerName: "publication_view" };
        if(String(p.rowid).match(/^[0-9]{0,}$/)){
            data.rowid = p.rowid;
        }
        this.props.Actions.exec(data, "products/").then((res) => {
            let hideOption={};
            if (isApiErr(res)) {
                this.props.PBActions.enable({ text: res.message || "Record not found." });
            }else{
                if(res.payload && res.payload.list && res.payload.list.length){
                    this.setState({ children: "viewDetail", detail : deepclone(res.payload.list[0]) });
                }else{
                    navigateTo({url:"/publication"});
                }
            }
            hideProgressBar(this.props,hideOption);
        }, (err) => {
            this.props.PBActions.enable({ text: err });
            hideProgressBar(this.props);
        });
    }
    getParams(props){
        if(props.match && props.match.params && props.match.params.ref) {
            this.setState({ params : props.match.params||{} });
            this.props.NavbarActions.update({ type: "admin", "current_page": "/publication/"+props.match.params.ref });
            if(isAny(props.match.params.ref,/^[0-9]{0,}$/)){
                this.getDetail({rowid:props.match.params.ref});
            }else if(isAny(props.match.params.ref,/^add$/)){
                this.setState({ children: "viewAdd", add:true, detail:null });
            }
        }else{
            this.props.NavbarActions.update({ type: "admin", "current_page": "/publication" });
            this.getList({
                limit : [" LIMIT ",this.state.pager.current,",",this.state.pager.perpage].join(" ")
            });
        }
    }
    onloadmore(){
        this.getList({
            limit : [" LIMIT ",this.state.pager.current,",",this.state.pager.perpage].join(" ")
        });
    }
    componentDidMount() {
        this.props.PBActions.enable({ show: false });
        this.props.NavbarActions.update({ type: "admin", "current_page": "/publication" });
        this.getParams(this.props);
    }
    UNSAFE_componentWillUpdate(nextProps){
        if(nextProps.match && nextProps.match.url!=this.props.navbar.current_page){
            this.getParams(nextProps);
        }
    }
    render() {
        let {children, list , detail, loadmore, add} = this.state;
        return ( 
            <React.Fragment>
                <PG_Layout>
                    <div className="desktop">
                        {
                            (children=="viewList" && list) && (
                                <React.Fragment>
                                    <List data={list} callAction={this.props.Actions} callback={this.stateCallback} pbAction={this.props.PBActions} user={this.props.navbar?this.props.navbar.user:{}} loadmore={loadmore} onloadmore={this.onloadmore} />
                                </React.Fragment>
                            )||""
                        }
                        {
                            (children=="viewAdd") && (
                                <React.Fragment>
                                    <DetailComp add={add} itemDetail={deepclone(publicationModel.newItem)}  callAction={this.props.Actions} callback={this.stateCallback} pbAction={this.props.PBActions} user={this.props.navbar?this.props.navbar.user:{}} />
                                </React.Fragment>
                            )
                        }
                        {
                            (children=="viewDetail" && detail)&& (
                                <React.Fragment>
                                    <DetailComp add={add} itemDetail={this.state.detail}  callAction={this.props.Actions} callback={this.stateCallback} pbAction={this.props.PBActions} user={this.props.navbar?this.props.navbar.user:{}} />
                                </React.Fragment>
                            )
                        }
                        
                    </div>    
                </PG_Layout>
            </React.Fragment>
        )
    }
}

PG_Publication.propTypes = {
    NavbarActions: PropTypes.object.isRequired,
    PBActions: PropTypes.object.isRequired,
    Actions: PropTypes.object.isRequired,
    calls: PropTypes.object,
    navbar: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        calls: state.CallsReducer,
        navbar: state.NavbarR
    };
}

function mapDispatchToProps(dispatch) {
    return {
        NavbarActions: bindActionCreators(NavbarActions, dispatch),
        Actions: bindActionCreators(Actions, dispatch),
        PBActions: bindActionCreators(PBActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_Publication);