/* eslint-disable import/no-named-as-default */
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";

import '../vendor/css/app.css';
import '../vendor/css/widget.css';

import HomePage from "./home/HomePage";
import NotFoundPage from "./NotFoundPage";
import PG_Dashboard from "./pages/dashboard/PG_Dashboard";
import PG_User from "./pages/user/PG_User";
import PG_VerifyUser from "./pages/verify/PG_VerifyUser";
import config from "../config/configApp";
import PG_Pages from "./pages/pages/PG_Pages";
import ForgotPassword from "./forgotpassword/ForgotPassword";
import PG_ResetPassword from "./pages/reset/PG_ResetPassword";
import PG_Blog from "./pages/blog/PG_Blog";
import PG_SiteMenu from "./pages/sitemenu/PG_SiteMenu";
import PG_CompReload from "./pages/component-reload/PG_CompReload";
import PG_SectionContent from "./pages/section-data/PG_SectionContent";
import PG_Image from "./pages/image/PG_Image";
import PG_Event from "./pages/event/PG_Event";
import PG_Publication from "./pages/publications/PG_Publication";
import PG_Media from "./pages/media/PG_Media";
import PG_WebEditor from "./pages/webeditor/PG_WebEditor";


// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  constructor(props){
    super(props);
  }
  
  render() {
    return (
      <Switch basename={config.base_directory} >
          <Route exact path="/" component={HomePage} />
          <Route path="/login" component={HomePage} />
          <Route path="/verify/:id" component={PG_VerifyUser} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/reset/:id" component={PG_ResetPassword} />

          <Route path="/dashboard" component={PG_Dashboard} />
          <Route path="/sitemenu" component={PG_SiteMenu} />
          <Route path="/blog" component={PG_Blog} />
          <Route path="/event" component={PG_Event} />
          <Route path="/section-content" component={PG_SectionContent} />
          <Route path="/publication/:ref?" component={PG_Publication} />
          <Route path="/media" component={ PG_Media } />
          <Route path="/image" component={ PG_Image } />
          <Route path="/pages" component={PG_Pages} />
          <Route path="/user" component={PG_User} />
          <Route path="/webeditor" component={PG_WebEditor} />
          <Route path="/reload" component={PG_CompReload} />
          <Route component={NotFoundPage} />
        </Switch>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
