import initialState from '../../../reducers/initialState';
import { topbarActionType } from './TopbarActionType';

export default function TopbarR(state = initialState.topbar, action) {
  switch (action.type) {
    case topbarActionType.update:
      return action.payload;
    default:
      return state
  }
}