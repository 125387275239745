import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormField } from '../common/formfield/FormField';
import { deepclone } from '../helpers/helpers';

class LoginSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: { name: { value: "" }, email: { value: "" }, password: { value: "" } }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(s, p) {
    let login = deepclone(this.state.login);
    login[p.input.name].value = s.value;
    this.setState({ login });
  }
  handleBlur(s, p) {
    let login = deepclone(this.state.login);
    login[p.input.name].value = s.value;
    this.setState({ login });
  }
  handleSubmit(){
    let {email,password} = deepclone(this.state.login); 
    this.props.onSubmit({email:email.value,password:password.value});
  }
  render() {
    let { email, password } = deepclone(this.state.login);
    return (
      <React.Fragment>
        <div className="form-group">
          <FormField input={{ type: "text", className: "form-control", placeholder: "Name", name: "name" }} defaultValue={name.value} label="Name" onChange={this.handleChange} onBlur={this.handleBlur} />
        </div>
        <div className="form-group">
          <FormField input={{ type: "text", className: "form-control", placeholder: "Email", name: "email" }} defaultValue={email.value} label="Email" onChange={this.handleChange} onBlur={this.handleBlur} />
        </div>
        <div className="form-group">
          <FormField input={{ type: "password", className: "form-control", placeholder: "Password", name: "password" }} defaultValue={password.value} label="Password" onChange={this.handleChange} onBlur={this.handleBlur} />
        </div>
        <div className="form-group text-right">
            <button type="button" className="anchor fill theme noborder size-xs radius uppercase" onClick={this.handleSubmit}>
                <span className="spantxt">Run Setup</span>
            </button>
        </div>
      </React.Fragment>
    );
  }
}

LoginSetup.propTypes = {
  onSubmit: PropTypes.func
};

export default LoginSetup;