import React, { Component, Suspense } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NavbarActions from '../../common/navbar/NavbarAction';

import { history } from '../../../store/configureStore';
import PG_Layout from '../PG_Layout';

import { infoblock_template } from '../../common/template/template';

const PG_Page_List = React.lazy(() => import('./PG_Page_List'));
const PG_Page_View = React.lazy(() => import('./PG_Page_View'));

class PG_Pages extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        this.props.NavbarActions.update({ type: "admin", "current_page": "/pages" });
    }
    render() {
        const isDetail = (String(history.location.pathname).match(/pages\/[0-9]{0,}/));
        return (
            <PG_Layout>
                <div className="desktop"> 
                    {
                        (isDetail)&&(
                            <React.Fragment>
                                <Suspense fallback={infoblock_template({ theme : "bg-grey1 color-theme",contentalign:"flex justify-center text-center pad20","children" : "Loading information..." })}>
                                    <PG_Page_View/>
                                </Suspense>
                            </React.Fragment>
                        )||(
                            <React.Fragment>
                                <Suspense fallback={infoblock_template({ theme : "bg-grey1 color-theme",contentalign:"flex justify-center text-center pad20","children" : "Loading information..." })}>
                                    <PG_Page_List/>
                                </Suspense>
                            </React.Fragment>
                        )
                    }
                </div>
            </PG_Layout>
        )
    }
}

PG_Pages.propTypes = {
    NavbarActions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        calls: state.CallsReducer
    };
}

function mapDispatchToProps(dispatch) {
    return {
        NavbarActions: bindActionCreators(NavbarActions, dispatch),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_Pages);