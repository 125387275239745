import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../calls/CallsAction';
import * as PBActions from '../../common/progressbar/ProgressBarAction';

import { navigateTo, hideProgressBar, isApiErr } from '../../helpers/helpers';
import PG_PublicLayout from '../PG_PublicLayout';
import { history } from '../../../store/configureStore';

class PG_VerifyUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
            message: ""
        };
        this.verifyUser = this.verifyUser.bind(this);
    }
    verifyUser(values) {
        let setupData = {
            action: "verifyuser", tablename: "user",
            "columns": values
        }, hideOption = { duration: 3000 };
        this.props.Actions.exec(setupData, "authenticate/").then((res) => {
            this.setState({ message: res.message });
            if (isApiErr(res)) {
                this.props.PBActions.enable({ text: res.message || "Sorry, Unable to run verify. Please try again later." });
            } else {
                hideOption.duration = 0;
                navigateTo({url:"/login"});
            }
            hideProgressBar(this.props, hideOption);
        }, () => {
            this.props.PBActions.enable({ text: "Sorry, verification incompleted. Please try again later." });
            hideProgressBar(this.props);
        });
    }
    initializeVerify() {
        let pathname = history.location.pathname;
        pathname = String(pathname).substr(1, pathname.length); pathname = String(pathname).split("/");
        this.setState({ message: "Please wait... Validating Account" });
        if (pathname.length == 2 && pathname[1] != "") {
            this.verifyUser({ verify: pathname[1] });
        } else {
            this.setState({ message: "Link may be invalid" });
        }
    }
    componentDidMount() {
        this.initializeVerify();
    }

    render() {
        return (
            <PG_PublicLayout>
                <div className="flex fxc align-items-center justify-center">
                    <div className="flex-4 sm-6 xsm-8 xs-12">
                        <div className="widget pad30 text-center ">
                            <div className="widget-body">
                                <h5 className="font-semibold uppercase lead h6 margb30">Account Confirmation</h5>
                                <div dangerouslySetInnerHTML={{__html:this.state.message||""}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </PG_PublicLayout>
        )
    }
}

PG_VerifyUser.propTypes = {
    Actions: PropTypes.object.isRequired,
    PBActions: PropTypes.object.isRequired,
};

function mapStateToProps() {
    return { };
}

function mapDispatchToProps(dispatch) {
    return {
        Actions: bindActionCreators(Actions, dispatch),
        PBActions: bindActionCreators(PBActions, dispatch),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_VerifyUser);