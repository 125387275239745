import initialState from '../../../reducers/initialState';
import { navbarActionType } from './NavbarActionType';
import { deepclone } from '../../helpers/helpers';


export default function NavbarR(state = initialState.navbar, action) {
  let _state;
  switch (action.type) {
    case navbarActionType.update:
      _state = deepclone(state);
      _state = {..._state,...action.payload||{}}
      return _state;
    default:
      return state
  }
}