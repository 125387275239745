import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from './NavbarAction';
import * as TopbarAction from '../topbar/TopbarAction';
import { firstletter, navigateTo, isEmpty, newPromise, is_Admin } from '../../helpers/helpers';
import config from '../../../config/configApp';
import { getMenus, menulist } from './MenuData';
 
class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "user" : null,
            "app": {
                "list": [
                    {
                        "label": "Home",
                        "url": "/"
                    }
                ]
            },
            "admin": {
                "list": getMenus({usertype : "default"})
            }
        }
    }
    updateMenu(){
        if(this.props.nb && this.props.nb.user && this.props.nb.user.control){
            let {admin} = this.state;
            admin.list = getMenus({usertype : String(this.props.nb.user.control).toLowerCase() });
            this.setState({admin});
        }
    }
    navigate(p){
        navigateTo(p);
        this.props.TopbarAction.update({sidenav:false});
    }
    componentDidMount(){
        newPromise({},1000).then(()=>{
            this.updateMenu();
        });
    }
    UNSAFE_componentWillReceiveProps(){
        this.updateMenu();
    }
    render() {
        const props = this.props, webeditor = menulist.webeditor;
        return (
            <React.Fragment>
                <div className={[(String(props.nb && props.nb.type).match(/app/) ? "app-navbarmain" : "admin-navbarmain")].join(" ")}>
                    <div className="inner">
                        <div className="nav-wrapper">
                            {
                                (props.nb.type=="admin" && props.nb.user) && (
                                    <div className="nav-profile flex fxc align-items-center">
                                        <label className="nav-photo">
                                            <span className="background" style={{"backgroundImage":["url(",(!isEmpty(props.nb.user.photo))?(config.apiImgPath+props.nb.user.photo):"assets/images/user-pic.png","?",new Date().getMilliseconds(),")"].join("")}}></span>
                                        </label>
                                        <div className="nav-profile-txt flex grow h align-items-center fxc">
                                            <div className="">
                                                <p className="ellipsis oh margb5 flex-10">{props.nb.user.name}</p>
                                                <p className="ellipsis oh flex-10">{props.nb.user.email}</p>
                                            </div>
                                            <div className="padr10">
                                                <a href={null} onClick={()=>navigateTo({url : "/user/"+props.nb.user.id})} className="button fill theme circle size-xs nolh squarexs text-center oh margb0"><i className="ion-arrow-right-c relative"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <ul className="navlist">
                                {
                                    (props.nb && this.state[props.nb.type]) &&
                                    this.state[props.nb.type].list.map((item, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                {(props.nb.type=="app") && (
                                                    <li className={(item.url == props.nb.current_page) ? 'active' : ''}>
                                                        <a onClick={()=>navigateTo({url:item.url})} href={null} title={item.label}>{item.label}</a>
                                                    </li>
                                                )}
                                                {(props.nb.type=="admin") && (
                                                    <li className={(item.url == props.nb.current_page) ? 'active' : ''}>
                                                        <a onClick={this.navigate.bind(this,{url:item.url})} href={null} title={item.label}>
                                                            <span className="menu-icon">
                                                                <span className="txt">{firstletter(item.label)}</span>
                                                            </span>
                                                            <span className="menu-txt">{item.label}</span>
                                                        </a>
                                                    </li>
                                                )}
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {(props.nb.type=="admin" && is_Admin( props.nb.user)) && (
                                    <li className={(webeditor.key == props.nb.current_page) ? 'active' : ''}>
                                        <a onClick={this.navigate.bind(this,{url:webeditor.url})} href={null} title={webeditor.label}>
                                            <span className="menu-icon">
                                                <span className="txt">{firstletter(webeditor.label)}</span>
                                            </span>
                                            <span className="menu-txt">{webeditor.label}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Navbar.propTypes = {
    nb: PropTypes.object,
    topbar: PropTypes.object,
    Actions: PropTypes.object,
    TopbarAction: PropTypes.object
};

function mapStateToProps(state) {
    return {
        nb: state.NavbarR,
        topbar: state.TopbarR
    };
}

function mapDispatchToProps(dispatch) {
    return {
        Actions: bindActionCreators(Actions, dispatch),
        TopbarAction: bindActionCreators(TopbarAction, dispatch),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);