const config = {
    apiUrl: process.env.API_URL+'template/api/',
    apiImgPath: process.env.API_URL+"Asset/upload/",
    apiMediaPath: process.env.API_URL+"Asset/upload/media/",
    payload: {
        secret: process.env.PAYLOAD_SECRET,
        key: process.env.PAYLOAD_KEY,
        apiprefix: "api",
        connectkey : process.env.PAYLOAD_CONNECTKEY,
        origin : process.env.PAYLOAD_ORIGIN
    },
    base_directory : process.env.BASE_DIRECTORY,
    appinfo: {
        appname: "Admin", version: "v0.1", timeout : 2, isAKey : 1,
    },
    data: {
        currency : {
            type : "INR",
        },
        stock : { y : "Available","n":"Unavailable","1" : "Available","0":"Unavailable"},
        logReset: { user: {}, type: "app", "current_page": "", "sidenav": false },
        status_values : { "draft": "publish", "publish": "draft" },
        listPerPage : "200"
    },
    isLocal: true,
    lastchanges : true
}
export default config;