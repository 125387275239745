import { callsActionType } from './CallsActionType';

import {deepclone} from '../helpers/helpers';
import initialState from '../../reducers/initialState';

export default function CallsReducer(state = initialState.calls, action) {
  let _state;
  switch (action.type) {
    case callsActionType.request:
      _state = deepclone(state);
      _state = { ..._state,...action }; 
      if(action.reducerName){
        _state[action.reducerName] = _state[action.reducerName]||{};
      }
      return _state;
    case callsActionType.success:
    case callsActionType.fail:
      _state = deepclone(state);
      _state = { ..._state,...action }; 
      if(_state.reducerName){
        _state[_state.reducerName] = {...action.payload};
      }
      return _state;
    case callsActionType.updateAny:
      _state = deepclone(state);
      if(typeof action.callback === "function"){
        _state = action.callback(_state,action);
      }
      return _state;
    case callsActionType.patch:
      _state = deepclone(state);
      _state = {..._state,...action.patch||{}};
      return _state;
    default:
      return state
  }
}