import { history } from "../../store/configureStore";
import config from "../../config/configApp";

export function handleResponse(response) {
    return response.text().then(text => {
        const data = (text && JSON.parse(text));
        return data;
    });
}

export function newPromise(payload, timeout = 1000) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(payload);
        }, timeout);
    });
}
export function scrollPosition() {
    const config = {
        scrollTop: document.body.scrollTop || document.documentElement.scrollTop,
        scrollH: document.documentElement.scrollHeight,
        clientH: document.documentElement.clientHeight,
    }
    config.height = config.scrollH - config.clientH;
    config.scrolled = config.scrollTop / config.height;
    return config;
}
export function deepclone(o) {
    return JSON.parse(JSON.stringify(o));
}
export function loremipsum(p) {
    let lp = {
        "line": [
            "Voluptate magna ipsum eu exercitation exercitation ex in adipisicing.",
            "Mollit voluptate velit culpa ipsum elit nulla veniam tempor laborum aute mollit anim eiusmod.",
            "Dolore ea adipisicing est commodo pariatur excepteur ut culpa.",
            "Consectetur amet consectetur elit in est ex culpa.",
            "Cupidatat dolore dolor enim ad cupidatat adipisicing pariatur ea ex veniam ex adipisicing.",
            "Reprehenderit occaecat labore est eu sunt anim deserunt fugiat elit tempor labore sunt."
        ],
        "para": [
            "Nulla anim eiusmod laborum dolore sit deserunt excepteur. Tempor ipsum pariatur minim cupidatat dolore aliqua cupidatat. Nostrud ex laborum incididunt fugiat veniam esse voluptate consequat.",
            "Est quis nostrud quis reprehenderit anim ullamco ex commodo ipsum irure nisi ea. Et ad do excepteur ea et consequat ullamco elit anim excepteur tempor. Duis et cupidatat Lorem id quis deserunt excepteur irure labore qui non dolore magna.",
            "Ipsum aute proident eu commodo aliquip enim. Deserunt ex enim ipsum aliqua minim incididunt eiusmod do tempor laborum fugiat dolore et. Pariatur proident quis nisi aliqua nisi velit Lorem.",
            "Sint nulla voluptate laborum voluptate Lorem consequat magna magna et laborum magna enim. Ex proident ut incididunt sint elit excepteur nostrud elit cillum eiusmod. Est est proident cillum voluptate occaecat occaecat duis aliqua ullamco Lorem. Laboris et minim proident sit labore incididunt voluptate. Cupidatat esse nulla exercitation elit nulla ullamco officia.", "Amet consectetur sunt minim Lorem culpa occaecat enim culpa do ea incididunt consectetur aliquip. Consectetur fugiat sint nostrud ut. Sint excepteur fugiat veniam aute laboris incididunt irure nulla sunt magna. Eiusmod qui consequat irure ullamco. Commodo esse anim consequat cillum aliquip sunt magna irure sunt eu. Dolor consectetur est ex laboris labore ad Lorem."
        ]
    };
    let randnum = Math.floor(Math.random() * lp[p].length);
    return lp[p][randnum] || lp[p][0];
}

export function hideProgressBar(props, p = {}) {
    newPromise({}, p.duration || 5000).then(() => {
        props.PBActions.enable({ show: false });
    });
}

export function quilToolbarOptions() {
    let toolbar = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block', 'image', 'link', 'video'],

        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }, { 'direction': 'rtl' }],                         // text direction


        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']
    ];
    return toolbar;
}
export function quilToolbarVideoOptions() {
    let toolbar = [
        ['clean']
    ];
    return toolbar;
}

export function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        // Read the image via FileReader API and save image result in state.
        reader.onload = function (e) {
            // Add the file name to the data URL
            let dataURL = e.target.result;
            dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
            resolve({ file, dataURL });
        };

        reader.readAsDataURL(file);
    });
}

export function readFileAll(files) {
    const allPromises = [];
    for (var i in files) {
        allPromises.push(readFile(files[i]));
    }
    return Promise.all(allPromises);
}
export function navigateTo(p) {
    if(p.fn=="goBack"){
        history[p.fn]();
    }else{
        history[p.fn||"replace"](p.url);
    }
}
export function reloadTo() {
    var location = String(history.location.pathname).replace(/\/reload/,"");
    navigateTo({url:location});
}
export function urlSplit() {
    var location = String(history.location.pathname).split("/");
    if (location[0] == "/" || location[0] == "") { location.shift(); }
    return location;
}
export function isEmpty(text) {
    return String(String(text).trim()).match(/^(\s{0,})$/) ? true : false;
}
export function regxMatch(regx, text) {
    return String(text).match(regx) ? true : false;
}
export function setClass(_if, _true, _false = "") {
    return _if ? _true : _false;
}
export function ifElse(regx, _if, _false) {
    return String(_if).match(regx) ? _if : _false;
}
export function isDefined(regx, _if, _false) {
    return String(_if).match(regx) ? _false : _if;
}
export function bindAS(_this, eventsname) {
    for (const key in eventsname) {
        if (_this[eventsname[key]]) {
            _this[eventsname[key]] = _this[eventsname[key]].bind(_this);
        }
    }
}
export function bindToState(_this, _state, eventsname) {
    for (const key in eventsname) {
        if (_this[eventsname[key]]) {
            _state[eventsname[key]] = deepclone(_this[eventsname[key]]);
        }
    }
    return _state;
}
export function classNames(namelist, by = " ") {
    return namelist.join(by);
}
export function firstletter(text) {
    return String(text).substr(0, 1);
}


export function getLS(request, duration = 1) {
    let lsItem, payload;
    lsItem = localStorage.getItem(request.key), payload = (!isUndefined(lsItem)) ? JSON.parse(lsItem) : false;
    return newPromise(payload, duration);
}
export function setLS(request, duration = 1) {
    localStorage.setItem(request.key, JSON.stringify(request.value));
    let lsItem, payload;
    lsItem = localStorage.getItem(request.key), payload = (!isUndefined(lsItem)) ? JSON.parse(lsItem) : false;
    return newPromise(payload, duration);
}
export function getOrSetLS(request, duration = 0) {
    let lsItem, payload;
    lsItem = localStorage.getItem(request.key), payload = (!isUndefined(lsItem)) ? JSON.parse(lsItem) : false;
    if (payload) {
        return newPromise(payload, duration);
    } else {
        return setLS(request, duration);
    }
}


export function isAny(request, match) {
    return String(request).match(match) ? true : false;
}
export function isNumberOnly(request) {
    return (!isEmpty(request) && String(request).match(/^[0-9]{0,}$/i)) ? true : false;
}
export function isTrue(request) {
    return String(request).match(/true/ig) ? true : false;
}
export function isFalse(request) {
    return String(request).match(/false/ig) ? true : false;
}
export function isNull(request) {
    return String(request).match(/null/ig) ? true : false;
}
export function isUndefined(request) {
    return String(request).match(/undefined/ig) ? true : false;
}
export function isLength(request) {
    return request && request.length ? true : false;
}
export function isList(data,key){
    let flag = data && data[key];
    if(flag){ flag = data[key].length; }else{ flag=false;}
    return flag;
}

export function is_Admin(p) {
    return (p && String(p.role) == config.appinfo.isAKey) ? true : false
}
export function is_Alter(p) {
    return (p && String(p.allowed).match(/alter/i)) ? true : false;
}
export function isAdmin(p) {
    return (p && String(p.control).match(/admin/i)) ? true : false;
}
export function isAdminSelf(p, current) {
    return (p && String(p.id) == current.user_id) ? true : false;
}
export function isDeleteAllowed(p, current) {
    return (p.id != current.id && isAny(p.allowed, /delete/i)) ? true : false;
}
export function isApiErr(p) {
    return String(p.err).match(/true/);
}
export function authenticate(props = {}) {
    getLS({ key: "logger", duration: 0 }).then((logger) => {
        if (logger && logger.user) {
            let user = encrypt(logger.user, false);
            let isTimeout = timeout({ user });
            if (!isTimeout && props.navbarAction) {
                props.navbarAction.update({ user });
            }
            if (isTimeout) {
                navigateTo({ url: "/login" });
            }
        } else {
            navigateTo({ url: "/login" });
        }
    });
}
export function timeout(props = {}) {
    let user = props.user, endtime = new Date(user.timeout), currtime = new Date();
    return (currtime) > (endtime);
}

export function isMobile() {
    let navigator = window.navigator || {};
    return String(navigator.userAgent).match(/mobile|iphone/i) ? true : false;
}

export function enshuffle(str) {
    let _str, f = [], g = function (str, b) {
        let a = str, p = a.pop(), s = a.shift();
        b.push(s); b.push(p);
        if (a.length) { g(a, b); }
        return b;
    }
    _str = String(str).split("");
    f = g(_str, f);
    return f.join("");
}
export function deshuffle(str) {
    let _str, g = function (str, f, l) {
        let a = str, s1 = a.shift(), s2 = a.shift();
        f.push(s1); l.unshift(s2);
        if (a.length) { g(a, f, l); }
        return { f, l, output: (f.join("") + l.join("")) };
    }
    _str = String(str).split("");
    let o = g(_str, [], []);
    return o.output;
}
export function ens(p){
    let str = String(p.str).replace(/\s/g,"&nbsp;");
    for(var i=0; i<p.max; i++){
        str = (p.mode)?enshuffle(str):deshuffle(str);    
    }
    str = (p.mode)?str:str.replace(/&nbsp;/g," ")
    return str;
}
export function encrypt(str, flag) {
    let crypted;
    if (flag) { crypted = enshuffle(JSON.stringify(str)); } else { crypted = JSON.parse(deshuffle(str)); }
    return crypted;
}
export function strTojson(str,alt=null) {
    return (str && str != "") ? JSON.parse(str):alt;
}
export function stringify(str) {
    return JSON.stringify(str);
}

export function arraynoempty(value){
    var _val =[];
    for(var i in value){
        if(!isEmpty(value[i])){
            _val.push(value[i]);
        }
    }
    return _val;
}
export function array_to_stringjoin(value,joinby=","){
    var _val = "";
    if(value && !isEmpty(value) && typeof value ==="object"){
        _val = value.join(joinby);
    }
    if(typeof value ==="string"){
        _val = value;
    }
    return _val;
}
export function winReload(){
    window.location.reload(false);
}
export function strsplit(str,finder=","){
    var isstr = typeof str === "string",output=str;
    if(isstr){
        output = (isEmpty(str))?[]:String(str).split(finder);
    }
    return output;
}
export function state_search(item,searchkey){
    searchkey = String(searchkey).replace(/[+*]/g,"");
    var flag = isEmpty(searchkey)?true:false;
    var ptn = new RegExp("^"+searchkey+"|"+searchkey,"i");
    var searchable_item = JSON.stringify(item);
    if(String(searchable_item).match(ptn)){ 
        flag = true;
    }
    return flag;
}