import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { history } from '../../store/configureStore';
import { FormField } from '../common/formfield/FormField';
import { deepclone, isEmpty } from '../helpers/helpers';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formhide : false,
      forgotpassword: {
        email: {
          value: ""
        }
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  goto(url) {
    history.push(url);
  }
  handleChange(s, p) {
    let forgotpassword = deepclone(this.state.forgotpassword);
    forgotpassword[p.input.name].value = s.value;
    this.setState({ forgotpassword });
  }
  handleBlur(s, p) {
    let forgotpassword = deepclone(this.state.forgotpassword);
    forgotpassword[p.input.name].value = s.value;
    this.setState({ forgotpassword });
  }
  handleSubmit(){
    let {forgotpassword} = this.state; 
    this.props.onSubmit({email:forgotpassword.email.value}).then(()=>{
      forgotpassword.email.value = "";
      this.setState({ forgotpassword, formhide:true },()=>{
        this.setState({formhide:false});
      });
    });
  }
  handleKeyUp(s,p){
    let forgotpassword = deepclone(this.state.forgotpassword);
    forgotpassword[p.input.name].value = s.value;
    this.setState({ forgotpassword },()=>{
      let {email} = this.state.forgotpassword, isvalid = (isEmpty(email))?false:true; 
      let isEntered = String(s.e.keyCode).match(/13/);
      if(isvalid && this.props.onEnter && isEntered){
        this.handleSubmit();
      }
    });
  }
  render() {
    let { email } = deepclone(this.state.forgotpassword);
    return (
      <React.Fragment>
        {
          (!this.state.formhide) && (
            <div>
              <div className="form-group">
                <FormField input={{ type: "text", className: "form-control", placeholder: "Email", name: "email", autoFocus:true }} defaultValue={email.value} label="Email" onChange={this.handleChange} onBlur={this.handleBlur} onKeyUp={this.handleKeyUp} />
              </div>
              <div className="form-group text-right">
                <button type="button" className="anchor fill theme noborder size-xs radius capitalize" onClick={this.handleSubmit}>
                  <span className="relative padx20">Get Reset Access</span>
                </button>
              </div>
            </div>
          )
        }
      </React.Fragment>
    );
  }
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  onEnter: PropTypes.bool
};

export default ForgotPasswordForm;