import site from '../../config/configApp';
import * as helpers from '../helpers/helpers';

export const callsService = { connect, fileupload };

const headers = [{
    'Content-Type': 'application/json',
    "origin" : "*"
}];
function connect(payload,endpoint) {
    payload = Object.assign({...site.payload},payload);
    const requestOptions = {
        method: 'POST',
        headers: headers[0],
        body: JSON.stringify(payload),
    };
    return fetch(`${site.apiUrl}${endpoint}`, requestOptions)
        .then(helpers.handleResponse)
}
function fileupload(payload,endpoint) {
    const requestOptions = {
        method: 'POST',
        // mode : "no-cors",
        headers: { 
            "origin" : "*",
            // 'Content-Type': 'application/json',
            // cache: false,
            // processData: false,
            // 'Accept': 'application/json',
        },
        body: payload,
    };
    let call = fetch(`${site.apiUrl}${endpoint}`, requestOptions)
        return call.then(helpers.handleResponse);
}

export default callsService;


