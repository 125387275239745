export default {
  fuelSavings: {
    newMpg: '',
    tradeMpg: '',
    newPpg: '',
    tradePpg: '',
    milesDriven: '',
    milesDrivenTimeframe: 'week',
    displayResults: false,
    dateModified: null,
    necessaryDataIsProvidedToCalculateSavings: false,
    savings: {
      monthly: 0,
      annual: 0,
      threeYear: 0
    }
  },
  loginCred: {
    email: '',
    password: ''
  },
  progressbar : {
    show : false
  },
  navbar : {
    type : "app", user : {}, current_page : ""
  },
  topbar : {
    "sidenav" : false 
  },
  calls : {}
};


