import React, { Component } from 'react'

import { connect } from 'react-redux';
import { reloadTo } from '../../helpers/helpers';

class PG_CompReload extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        reloadTo();
    }
    render() {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}

PG_CompReload.propTypes = {
};

function mapStateToProps() {
    return {
    };
}

function mapDispatchToProps() {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PG_CompReload);