export const sectionDataModel = () => {
    let opt = {
        "options": {
            "bullets": {
                type: "radio", list: ["a", "b", "c"]
            },
            "lead": {
                type: "radio", list: ["h1", "h2", "h3", "h4", "h5", "h6"]
            },
            "lean": { type: "radio", list: ["regular", "light", "medium", "semibold", "bold", "extrabold"] },
            "decorate": { type: "radio", list: ["a", "b", "c"] },
            "grids": {
                "sizes": {
                    "xs": { type: "radio", list: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "None"] },
                    "xsm": { type: "radio", list: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "None"] },
                    "sm": { type: "radio", list: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "None"] },
                    "md": { type: "radio", list: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "None"] },
                    "lg": { type: "radio", list: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "None"] },
                },
                "alignment": {
                    "gridAlignX": ["start", "center", "end", "between", "around"], "gridAlignY": ["start", "center", "end", "stretch"],
                }
            },
            "minHeight": ["25", "50", "75", "100"],
        },
        "settings": {
            "className": { "type": "arraytext", "defaultValue": [] },
            "tag": { "type": "arraytext", "defaultValue": ["div"], "limit": 1 },
            "contentAs": { "type": "arraytext", "defaultValue": ["text"] },
        },
        "newitem": {
            "key": "",
            "value": [{ "value": "" }],
        }
    };
    return opt;
}