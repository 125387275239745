import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { hideProgressBar, isAny, isApiErr, isLength, navigateTo, state_search } from '../../helpers/helpers'
import { ProductPhotoFetch } from '../../common/productphotofetch/ProductPhotoFetch'
import { FormField } from '../../common/formfield/FormField'
import config from '../../../config/configApp'

List.propTypes = {
    data : PropTypes.any,
    user: PropTypes.object,
    callback: PropTypes.func,
    callAction : PropTypes.any,
    pbAction : PropTypes.any,
    loadmore : PropTypes.bool,
    onloadmore: PropTypes.func
}
export function List({data,user,callback,callAction,pbAction,loadmore,onloadmore}){
    const pager = {
        title : "Blog List"
    }
    const [list,setList] = useState([...data]);
    if(list.length!=data.length){
        setList(data);
    }
    const [searchKey,setSearchKey] = useState(null);
    const this_search = (p)=>{
        return state_search(p,searchKey);
    }
    const this_input_keyup = ()=>(field)=>{
        setSearchKey(field.value);
    }
    const this_publish = (p)=>()=>{
        let status_values = config.data.status_values, columns = { status: status_values[p.item.status] || "draft" }, rowid = p.item.id;
        pbAction.enable({ show: true, text: "Updating.." });
        callAction.exec({ action: "update", tablename: "publication", rowid, reducerName: "publication_update", columns }, "products/").then((res) => {
            let hideOption={};
            if (isApiErr(res)) {
                pbAction.enable({ text: res.message || "Record not updated." });
            } else {
                let prevlist = [...list];
                prevlist[p.i].status = columns.status;
                setList(prevlist); 
                hideOption.duration=1000;
                pbAction.enable({ text: "Successfully updated.." });
                callback({ref:"itemPublish", list:prevlist});
            }
            hideProgressBar({PBActions:pbAction},hideOption);
        }, (err) => {
            //console.log(err);
            pbAction.enable({ text: err });
            hideProgressBar({PBActions:pbAction});
        });
    }
    
    return (
        <React.Fragment>
            <div className="widget">
                <div className="widget-header flex align-items-center justify-between position-sticky top w">
                    <div>
                        <div className="flex align-items-center">
                            <div className="widget-action-list">
                                <ul>
                                    <li>
                                        <a className="icon-item" href={null} onClick={()=>navigateTo({url:'/dashboard'})}><span className="icon-span"> <i className={["ion-arrow-left-c"].join("")}></i> </span></a>
                                    </li>
                                </ul>
                            </div>
                            <div><h6 className="widget-title">{pager.title}</h6></div>
                        </div>
                    </div>
                    <div className="">
                        <div className="widget-action-list">
                            <ul className="">
                                <li></li>
                                {
                                    (isLength(list)) && (
                                        <React.Fragment>
                                            <li>
                                                <a href={null} onClick={() => navigateTo({ url: "/publication/add" })}>
                                                    <span className="relative"><i className="ion-plus"></i> New</span>
                                                </a>
                                            </li>
                                        </React.Fragment>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {
                    (isLength(list)) && (
                        <div className="bg grey1 pad10">
                            <FormField input={{ type: "text", className: "form-control", placeholder: "Search", name: "search" }} columnname="search" defaultValue={searchKey} label="" onKeyUp={this_input_keyup({name:"searchKey"})} ></FormField>
                        </div>
                    )||""
                }
                <div className="widget-body">
                    <div className="widget-row rowtool-static">
                        {
                            (isLength(list)) && (
                                list.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className={["rowtool-target cursor-pointer",((this_search(item))?"":"nodisplay")].join(" ")}>
                                                <div className="flex justify-between align-items-center pad cursor-pointer h relative">
                                                    <div className="flex-8">
                                                        <div className="flex">
                                                            {
                                                                (item.photo) && (
                                                                    <div className="icon-w-xs">
                                                                        <div>
                                                                            <div className="user-photo-label flex fxc align-items-center">
                                                                                <label className="nav-photo">
                                                                                    <ProductPhotoFetch props={{photo:item.photo, usethumb : true}}/>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )||("")
                                                            }
                                                            <div className="flex-75 cursor-pointer" onClick={() => navigateTo({ url: '/publication/' + item.id })}>
                                                                <span className={["abs topleft h bg padl3", item.status].join(" ")}></span>
                                                                <label className="lead h7 marg0 text-ellipsis nowrap"> {item.title}</label>
                                                                <p className="text-ellipsis oh marg0" title={item.description}>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-4 xsm-12">
                                                        <div className="flex padcell align-items-stretch justify-end">
                                                            <div>
                                                                <a href={null} title="View" onClick={() => navigateTo({ url: '/publication/' + item.id })} className="anchor fill info button-input radius">
                                                                    <span className="relative padx10"> <i className={["ion-android-open"].join("")}></i> View </span>
                                                                </a>
                                                            </div>
                                                            {
                                                                (isAny(user.allowed,/update/i))&&(
                                                                    <div>
                                                                        <a className={["anchor fill button-input radius", ((item.status == 'draft' ? 'warning' : 'theme'))].join(" ")} href={null} title="Publish/Draft" onClick={this_publish({item, i})}>
                                                                            <span className="relative padx10"> <i className={[(item.status == 'draft' ? 'ion-thumbsdown' : 'ion-thumbsup')].join("")}></i> <span className="visible-from-xsm">{(item.status == 'draft' ? 'Draft' : 'Publish')}</span></span> 
                                                                        </a>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            )
                        }
                        {
                            (loadmore) && (
                                <React.Fragment>
                                    <div className={["rowtool-target"].join(" ")}>
                                        <div className="flex wrap align-items-center justify-between pad cursor-pointer h relative">
                                            <div className="flex-100">
                                                <a href={null} className="anchor fill button-input radius theme" onClick={()=>{onloadmore()}}>
                                                    <span className="padx10">Load More...</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}