import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';
import ProgressBarR from '../components/common/progressbar/ProgressBarReducer';
import NavbarR from '../components/common/navbar/NavbarReducer';
import TopbarR from '../components/common/topbar/TopbarReducer';
import CallsReducer from '../components/calls/CallsReducer';

const rootReducer = combineReducers({
  NavbarR,
  TopbarR,
  ProgressBarR,
  CallsReducer
});


export default rootReducer;