import React from 'react';

export function infoblock_template(p){
    return (
        <React.Fragment>
            <div className={[p.theme||"pad10 radius bg-grey1"]}>
                <div className={[p.contentalign||"flex"]}>
                    <div>
                        <span className="" dangerouslySetInnerHTML={{__html:p.children}}></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}