export const userModel = {
    newUser: { "name": "", "email": "", "password": "", "description": "", "photo": "", "category": "", "allowed": "", "control": "","user_id":1 },
    "userTableFields": [
        
    ],
    allowedControl : {
        options: [{ value: 'create', label: "Create" }, { value: 'update', label: "Update" }, { value: 'delete', label: "Delete" }, { value: 'publish', label: "Publish" }]
    },
    userControl : {
        options: [{ value: 'Admin', label: "Admin" }, { value: 'User', label: "User" }]
    }
} 